// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-container {
  height: 36px;
}
.button-container button {
  cursor: pointer;
  width: 100%;
  height: 100%;
  border: 1px solid #006de9;
  border-radius: 10px;
  font-size: 14px;
  line-height: 21px;
}
.button-container button:disabled {
  cursor: not-allowed;
  opacity: 0.4;
}
.button-container button:focus-visible {
  outline: 2px solid black;
}
.button-container-primary button {
  color: white;
  background-color: #006de9;
}
.button-container-secondary button {
  color: #006de9;
  background-color: white;
}
`, "",{"version":3,"sources":["webpack://./src/components/shared/button/index.less"],"names":[],"mappings":"AAEA;EACE,YAAA;AADF;AAAA;EAII,eAAA;EACA,WAAA;EACA,YAAA;EACA,yBAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;AADJ;AAEI;EACE,mBAAA;EACA,YAAA;AAAN;AAEI;EACE,wBAAA;AAAN;AAIE;EAEI,YAAA;EACA,yBAAA;AAHN;AAOE;EAEI,cAAA;EACA,uBAAA;AANN","sourcesContent":["@import '../../../style/colors.less';\n\n.button-container {\n  height: 36px;\n\n  button {\n    cursor: pointer;\n    width: 100%;\n    height: 100%;\n    border: 1px solid @BgBorderBrand;\n    border-radius: 10px;\n    font-size: 14px;\n    line-height: 21px;\n    &:disabled {\n      cursor: not-allowed;\n      opacity: 0.4;\n    }\n    &:focus-visible {\n      outline: 2px solid black;\n    }\n  }\n\n  &-primary {\n    button {\n      color: white;\n      background-color: @BgBorderBrand;\n    }\n  }\n\n  &-secondary {\n    button {\n      color: @BgBorderBrand;\n      background-color: white;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
