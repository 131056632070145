import Button from '../../shared/button';
import './index.less';

const SuspendedModal = ({handleClose, initialFocus}) => {
  return (
    <div className="suspended-modal">
      <div className="suspended-modal__header">
        <h1 ref={initialFocus} tabIndex="-1">
          Welcome back!
        </h1>
      </div>
      <div className="suspended-modal__content">
        <p>
          Your account has been archived, but no worries - we can help get you
          reactivated.
        </p>
        <p className="suspended-modal__support">
          Contact our support team,&nbsp;
          <a
            aria-label="socialtables support email link"
            href="mailto:ST.support@cvent.com"
          >
            ST.support@cvent.com
          </a>
          , or call&nbsp;
          <a
            aria-label="socialtables support phone number"
            href="tel:555-666-7777"
          >
            866-318-4357
          </a>
          . &nbsp;Our phone support is available Monday through Friday, from 9
          am to 10 pm ET.
        </p>
      </div>
      <div className="suspended-modal__actions">
        <Button
          inputFieldKey="close"
          text="Close"
          isPrimary={false}
          onClick={handleClose}
        />
      </div>
    </div>
  );
};

export default SuspendedModal;
