const AlertTriangle = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.25 1.64966L11.5 8.74966C11.6 8.94966 11.7 9.24966 11.7 9.54966C11.7 10.3997 11.05 11.0497 10.2 11.0497H1.75C1.45 11.0497 1.2 10.9497 0.95 10.7997C0.65 10.5997 0.4 10.2997 0.3 9.89966C0.2 9.49967 0.25 9.09966 0.45 8.74966L4.7 1.64966C4.85 1.44966 5 1.29966 5.2 1.14966C5.9 0.749665 6.8 0.999665 7.25 1.64966ZM1.75 9.99966H10.2C10.5 9.99966 10.7 9.79966 10.7 9.39966C10.7 9.34966 10.7 9.24966 10.65 9.19966L6.45 2.14966C6.3 1.94966 6 1.84966 5.75 1.99966C5.7 2.04966 5.65 2.09966 5.6 2.19966L1.35 9.24966C1.27426 9.36327 1.28457 9.44819 1.29403 9.52616C1.29706 9.55114 1.3 9.5754 1.3 9.59966C1.3 9.74966 1.4 9.84966 1.5 9.89966C1.6 9.99966 1.65 9.99966 1.75 9.99966ZM6 3.99966C5.7 3.99966 5.5 4.19966 5.5 4.49966V6.49966C5.5 6.79966 5.7 6.99966 6 6.99966C6.3 6.99966 6.5 6.79966 6.5 6.49966V4.49966C6.5 4.19966 6.3 3.99966 6 3.99966ZM6 9.12466C6.34518 9.12466 6.625 8.84484 6.625 8.49966C6.625 8.15449 6.34518 7.87466 6 7.87466C5.65482 7.87466 5.375 8.15449 5.375 8.49966C5.375 8.84484 5.65482 9.12466 6 9.12466Z"
      fill="#DB2C00"
    />
  </svg>
);

export default AlertTriangle;
