import {useContext} from 'react';
import {globalUIContext} from '../../utils/global-ui-helpers';
import FormHeader from '../../components/shared/form-header';
import emailIcon from '../../assets/email.svg';
import PlanSummary from '../../components/plan-summary';
import PlanSummaryMobile from '../../components/plan-summary-mobile';
import {FREE_PLAN_NAME} from '../../constants/plan';
import {CREATE_AN_ACCOUNT_TITLE} from '../../constants/page-title';

import './index.less';

const VerifyEmailPage = () => {
  const {isMobile, email, selectedPlanType} = useContext(globalUIContext);
  const isFree = selectedPlanType === FREE_PLAN_NAME;
  return (
    <>
      <div
        className="verify-email-cta"
        role="status"
        aria-labelledby="verify-email-cta__heading"
        aria-describedby="verify-email-cta__body"
        tabIndex="-1"
      >
        <FormHeader
          current={1}
          max={isFree ? 2 : 3}
          headerText={CREATE_AN_ACCOUNT_TITLE}
        />
        <img
          className="verify-email-cta__icon"
          src={emailIcon}
          alt="verify your email image"
          aria-hidden="true"
        />
        <h2 className="verify-email-cta__heading">Verify your email</h2>
        <span className="verify-email-cta__body">
          We’ve sent a verification message to <b>{email || 'your email'}</b>.
          Click on the link in your email to finalize account creation. This
          link will expire in 24 hours.
        </span>
      </div>
      {isMobile ? (
        <PlanSummaryMobile planType={selectedPlanType} />
      ) : (
        <PlanSummary planType={selectedPlanType} />
      )}
    </>
  );
};
export default VerifyEmailPage;
