const ErrorCircleIcon = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="&#194;&#169; v1 x-circle">
      <path
        id="Flattened"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.0013 1.25C3.93464 1.25 0.667969 4.51667 0.667969 8.58333C0.667969 12.65 3.93464 15.9167 8.0013 15.9167C12.068 15.9167 15.3346 12.65 15.3346 8.58333C15.3346 4.51667 12.068 1.25 8.0013 1.25ZM8.0013 14.5833C4.66797 14.5833 2.0013 11.9167 2.0013 8.58333C2.0013 5.25 4.66797 2.58333 8.0013 2.58333C11.3346 2.58333 14.0013 5.25 14.0013 8.58333C14.0013 11.9167 11.3346 14.5833 8.0013 14.5833ZM10.468 6.11667C10.2013 5.85 9.8013 5.85 9.53463 6.11667L8.0013 7.65L6.46797 6.11667C6.2013 5.85 5.8013 5.85 5.53464 6.11667C5.26797 6.38333 5.26797 6.78333 5.53464 7.05L7.06797 8.58333L5.53464 10.1167C5.26797 10.3833 5.26797 10.7833 5.53464 11.05C5.66797 11.1833 5.8013 11.25 6.0013 11.25C6.2013 11.25 6.33463 11.1833 6.46797 11.05L8.0013 9.51667L9.53463 11.05C9.66797 11.1833 9.86797 11.25 10.0013 11.25C10.1346 11.25 10.3346 11.1833 10.468 11.05C10.7346 10.7833 10.7346 10.3833 10.468 10.1167L8.93463 8.58333L10.468 7.05C10.7346 6.78333 10.7346 6.38333 10.468 6.11667Z"
        fill="#DB2C00"
      />
    </g>
  </svg>
);

export default ErrorCircleIcon;
