import classNames from 'classnames';
import Button from '../../shared/button';
import './index.less';

const HelpAndSupportModal = ({handleClose, isMobile, initialFocus}) => {
  const helpAndSupportClassNames = classNames('help-and-support-modal', {
    'help-and-support-modal--small': isMobile,
  });
  return (
    <div className={helpAndSupportClassNames}>
      <div
        className="help-and-support-modal__header"
        tabIndex="-1"
        ref={initialFocus}
      >
        <h1>Help & Support</h1>
      </div>
      <div className="help-and-support-modal__content">
        <p>
          For support, please contact customer service,{' '}
          <a
            aria-label="socialtables support email link"
            href="mailto:ST.support@cvent.com"
          >
            ST.support@cvent.com
          </a>{' '}
          or call 866-318-4357. Phone support is available Monday to Friday, 9am
          - 10pm ET.
        </p>
      </div>
      <div className="help-and-support-modal__actions">
        <Button
          inputFieldKey="close"
          className="help-and-support-modal__actions__close-btn"
          text="Close"
          onClick={handleClose}
          type="button"
          isPrimary={false}
        />
      </div>
    </div>
  );
};

export default HelpAndSupportModal;
