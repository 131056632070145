import {
  MARKETO_FORM_TYPES,
  HC_BUSINESS_TYPES_LIST,
} from '../constants/form-fields';
import {PRO_PLAN_NAME} from '../constants/plan';

export const getMarketoFormType = ({businessType = '', planType}) => {
  if (businessType && HC_BUSINESS_TYPES_LIST.includes(businessType)) {
    if (planType == PRO_PLAN_NAME) {
      return MARKETO_FORM_TYPES.PROPERTY_REGISTRATION_PRO;
    }
    return MARKETO_FORM_TYPES.PROPERTY_REGISTRATION;
  }
  if (planType == PRO_PLAN_NAME) {
    return MARKETO_FORM_TYPES.FREEMIUM_SUCCESS_PRO;
  }
  return MARKETO_FORM_TYPES.FREEMIUM_SUCCESS;
};

export const focusElementById = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.focus();
  }
};

export const focusField = (fieldErrors, fieldOrder) => {
  for (const fieldKey of fieldOrder) {
    if (fieldErrors[fieldKey]) {
      focusElementById(`field-${fieldKey}`);
      break;
    }
  }
};
