// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.circular-progress-bar {
  position: relative;
  width: 90px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.circular-progress-bar__svg {
  max-width: 100%;
  vertical-align: middle;
  transform: rotate(-90deg);
}
.circular-progress-bar__track,
.circular-progress-bar__indication {
  fill: transparent;
}
.circular-progress-bar__track {
  stroke: #d9dcde;
}
.circular-progress-bar__indication {
  stroke: #b5226e;
}
.circular-progress-bar__label {
  position: absolute;
  top: 37px;
  left: 21px;
  font-size: 14px;
}
`, "",{"version":3,"sources":["webpack://./src/components/shared/circular-progress-bar/index.less"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AADF;AAEE;EACE,eAAA;EACA,sBAAA;EACA,yBAAA;AAAJ;AAEE;;EAEE,iBAAA;AAAJ;AAEE;EACE,eAAA;AAAJ;AAEE;EACE,eAAA;AAAJ;AAEE;EACE,kBAAA;EACA,SAAA;EACA,UAAA;EACA,eAAA;AAAJ","sourcesContent":["@import '../../../style/colors.less';\n\n.circular-progress-bar {\n  position: relative;\n  width: 90px;\n  height: 90px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  &__svg {\n    max-width: 100%;\n    vertical-align: middle;\n    transform: rotate(-90deg);\n  }\n  &__track,\n  &__indication {\n    fill: transparent;\n  }\n  &__track {\n    stroke: @BgBorderNeutral15;\n  }\n  &__indication {\n    stroke: @SocialTablesPink;\n  }\n  &__label {\n    position: absolute;\n    top: 37px;\n    left: 21px;\n    font-size: 14px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
