// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.password-input-field {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 300px;
}
.password-input-field label {
  color: #69717a;
  font-size: 16px;
  margin-bottom: 8px;
}
.password-input-field__input {
  border: 1px solid #69717a;
  border-radius: 6px;
  padding: 8px 16px;
  font-size: 16px;
  line-height: 24px;
}
.password-input-field__input-error {
  border: 1px solid #db2c00;
}
.password-input-field__eye-icon {
  background: none;
  border: none;
  padding: 0;
  position: absolute;
  right: 16px;
  top: 32px;
  cursor: pointer;
}
.password-input-field__error {
  display: flex;
  align-items: flex-end;
  height: 18px;
  font-size: 12px;
  color: #db2c00;
}
.password-input-field__error svg {
  margin-right: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/components/shared/password-input-field/index.less"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,YAAA;AADF;AAHA;EAMI,cAAA;EACA,eAAA;EACA,kBAAA;AAAJ;AAEE;EACE,yBAAA;EACA,kBAAA;EACA,iBAAA;EACA,eAAA;EACA,iBAAA;AAAJ;AACI;EACE,yBAAA;AACN;AAEE;EACE,gBAAA;EACA,YAAA;EACA,UAAA;EACA,kBAAA;EACA,WAAA;EACA,SAAA;EACA,eAAA;AAAJ;AAEE;EACE,aAAA;EACA,qBAAA;EACA,YAAA;EACA,eAAA;EACA,cAAA;AAAJ;AALE;EAOI,iBAAA;AACN","sourcesContent":["@import '../../../style/colors.less';\n\n.password-input-field {\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  width: 300px;\n  label {\n    color: @TextIconInteractive-soft;\n    font-size: 16px;\n    margin-bottom: 8px;\n  }\n  &__input {\n    border: 1px solid @TextIconInteractive-soft;\n    border-radius: 6px;\n    padding: 8px 16px;\n    font-size: 16px;\n    line-height: 24px;\n    &-error {\n      border: 1px solid @TextIconInteractive-danger;\n    }\n  }\n  &__eye-icon {\n    background: none;\n    border: none;\n    padding: 0;\n    position: absolute;\n    right: 16px;\n    top: 32px;\n    cursor: pointer;\n  }\n  &__error {\n    display: flex;\n    align-items: flex-end;\n    height: 18px;\n    font-size: 12px;\n    color: @TextIconInteractive-danger;\n    svg {\n      margin-right: 4px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
