const ChevronLeftIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="&#194;&#169; v1 chevron-left">
      <path
        id="Flattened"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.93594 7.9987L10.4693 4.46536C10.7359 4.1987 10.7359 3.7987 10.4693 3.53203C10.2026 3.26536 9.8026 3.26536 9.53594 3.53203L5.53594 7.53203C5.26927 7.7987 5.26927 8.1987 5.53594 8.46536L9.53594 12.4654C9.66927 12.5987 9.86927 12.6654 10.0026 12.6654C10.1359 12.6654 10.3359 12.5987 10.4693 12.4654C10.7359 12.1987 10.7359 11.7987 10.4693 11.532L6.93594 7.9987Z"
        fill="#69717A"
      />
    </g>
  </svg>
);

export default ChevronLeftIcon;
