// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.saml-redirect {
  width: 100%;
}
.saml-redirect__header {
  font-size: 32px;
  font-weight: 300;
  display: flex;
  justify-content: center;
  padding-top: 250px;
  color: #303c6b;
  margin-bottom: 16px;
}
.saml-redirect__spinner {
  width: 100%;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.saml-redirect__spinner img {
  height: 100px;
}
`, "",{"version":3,"sources":["webpack://./src/routes/saml-redirect-form/index.less"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;AACE;EACE,eAAA;EACA,gBAAA;EACA,aAAA;EACA,uBAAA;EACA,kBAAA;EACA,cAAA;EACA,mBAAA;AACJ;AAEE;EACE,WAAA;EACA,oBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAAJ;AALE;EAOI,aAAA;AACN","sourcesContent":[".saml-redirect {\n  width: 100%;\n\n  &__header {\n    font-size: 32px;\n    font-weight: 300;\n    display: flex;\n    justify-content: center;\n    padding-top: 250px;\n    color: #303c6b;\n    margin-bottom: 16px;\n  }\n\n  &__spinner {\n    width: 100%;\n    padding-bottom: 20px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    img {\n      height: 100px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
