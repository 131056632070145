import {useState, useEffect} from 'react';
import classNames from 'classnames';
import PasswordInputField from '../shared/password-input-field';
import Button from '../shared/button';
import ErrorBanner from '../shared/error-banner';
import {CheckCircleIcon, ErrorCircleIcon} from '../shared/icons';
import {
  PASSWORD_FIELD,
  CONFIRM_PASSWORD_FIELD,
} from '../../constants/form-fields';
import {focusElementById} from '../../utils/form-helpers';
import './index.less';

// pull from server when implemented
const DEFAULT_PASSWORD_REQUIREMENTS = {
  min_pw_length: 12,
  min_number_special_characters: 1,
  require_mixed_case_pw: true,
};
const RED = 'RED';
const GREEN = 'GREEN';
const GRAY = 'GRAY';
const CHARACTER_LIMIT = 'CHARACTER_LIMIT';
const UPPERCASE_LETTER = 'UPPERCASE_LETTER';
const LOWERCASE_LETTER = 'LOWERCASE_LETTER';
const SPECIAL_CHARACTER = 'SPECIAL_CHARACTER';
const MISMATCH = 'MISMATCH';
const DEFAULT_FORM_STATUS = {
  [CHARACTER_LIMIT]: GRAY,
  [UPPERCASE_LETTER]: GRAY,
  [LOWERCASE_LETTER]: GRAY,
  [SPECIAL_CHARACTER]: GRAY,
  [MISMATCH]: GRAY,
};

const ARIA_FORM_REQUIREMENTS = {
  [CHARACTER_LIMIT]: '12 characters',
  [UPPERCASE_LETTER]: 'An uppercase letter',
  [LOWERCASE_LETTER]: 'A lowercase letter',
  [SPECIAL_CHARACTER]: 'A special character (e.g. !@#$%)',
  [MISMATCH]: 'Passwords must match',
};

const getAriaAlertMessage = (formStatus) => {
  const errors = [];
  Object.keys(formStatus).forEach((key) => {
    if (formStatus[key] === RED) {
      errors.push(key);
    }
  });
  if (errors.length < 1) {
    return null;
  }
  const message = errors.reduce((message, error) => {
    return message + ARIA_FORM_REQUIREMENTS[error] + ',';
  }, 'The following requirements are not met: ');
  return message.slice(0, -1) + '.';
};

const CreatePasswordForm = ({
  handleResetPassword = () => {},
  isFirstPassword = false,
  resError,
  setResError,
}) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(resError || '');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formStatus, setFormStatus] = useState(DEFAULT_FORM_STATUS);
  useEffect(() => {
    if (!password.length) {
      setFormStatus(DEFAULT_FORM_STATUS);
      setIsSubmitted(false);
    }
  }, [password]);
  const getStatusItemClassNames = (status) =>
    classNames('create-password-form__status__item', {
      'create-password-form__status__item__error': status === RED,
      'create-password-form__status__item__success':
        status === GREEN && password.length,
    });
  const handlePasswordChange = (value) => {
    setResError(null);
    setErrorMessage('');
    setPassword(value);
    const newFormStatus = {
      ...formStatus,
    };
    // CHARACTER_LIMIT
    if (value.length >= DEFAULT_PASSWORD_REQUIREMENTS.min_pw_length) {
      newFormStatus[CHARACTER_LIMIT] = GREEN;
    } else {
      newFormStatus[CHARACTER_LIMIT] =
        formStatus[CHARACTER_LIMIT] === RED && isSubmitted ? RED : GRAY;
    }
    // UPPERCASE_LETTER
    if (
      DEFAULT_PASSWORD_REQUIREMENTS.require_mixed_case_pw &&
      /(?=.*[A-Z])/.test(value)
    ) {
      newFormStatus[UPPERCASE_LETTER] = GREEN;
    } else {
      newFormStatus[UPPERCASE_LETTER] =
        formStatus[UPPERCASE_LETTER] === RED && isSubmitted ? RED : GRAY;
    }
    // LOWERCASE_LETTER
    if (
      DEFAULT_PASSWORD_REQUIREMENTS.require_mixed_case_pw &&
      /(?=.*[a-z])/.test(value)
    ) {
      newFormStatus[LOWERCASE_LETTER] = GREEN;
    } else {
      newFormStatus[LOWERCASE_LETTER] =
        formStatus[LOWERCASE_LETTER] === RED && isSubmitted ? RED : GRAY;
    }
    // SPECIAL_CHARACTER
    if (DEFAULT_PASSWORD_REQUIREMENTS.min_number_special_characters > 0) {
      const foundSpecialCharacters =
        value.match(/[`~@#$%^!&*()_+\-=\[\]{};':"\\|,.<>\/?]/g)?.length || 0;
      if (
        foundSpecialCharacters >=
        DEFAULT_PASSWORD_REQUIREMENTS.min_number_special_characters
      ) {
        newFormStatus[SPECIAL_CHARACTER] = GREEN;
      } else {
        newFormStatus[SPECIAL_CHARACTER] =
          formStatus[SPECIAL_CHARACTER] === RED && isSubmitted ? RED : GRAY;
      }
    }
    // MISMATCH
    if (confirmPassword && value === confirmPassword) {
      newFormStatus[MISMATCH] = GREEN;
    } else {
      newFormStatus[MISMATCH] =
        formStatus[MISMATCH] === RED && isSubmitted ? RED : GRAY;
    }
    setFormStatus(newFormStatus);
  };
  const handleConfirmPasswordChange = (value) => {
    setResError(null);
    setErrorMessage('');
    setConfirmPassword(value);
    // MISMATCH
    let status;
    if (password === value) {
      status = GREEN;
    } else {
      status = formStatus[MISMATCH] === RED && isSubmitted ? RED : GRAY;
    }
    setFormStatus({
      ...formStatus,
      [MISMATCH]: status,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    const unmetPasswordRequirements = Object.keys(formStatus).filter(
      (requirement) =>
        formStatus[requirement] === GRAY || formStatus[requirement] === RED
    );
    if (unmetPasswordRequirements.length) {
      const newFormStatus = {
        ...formStatus,
      };
      unmetPasswordRequirements.forEach(
        (requirement) => (newFormStatus[requirement] = RED)
      );
      setFormStatus(newFormStatus);
      setErrorMessage('Password requirements were not met.');
      focusElementById(`field-${PASSWORD_FIELD}`);
    } else {
      handleResetPassword({password, confirmPassword});
    }
  };
  return (
    <div className="create-password-form">
      <div className="create-password-form__status">
        <span className={getStatusItemClassNames(formStatus[CHARACTER_LIMIT])}>
          {formStatus[CHARACTER_LIMIT] === RED ? (
            <ErrorCircleIcon />
          ) : (
            <CheckCircleIcon />
          )}
          12 characters
        </span>
        <span className={getStatusItemClassNames(formStatus[UPPERCASE_LETTER])}>
          {formStatus[UPPERCASE_LETTER] === RED ? (
            <ErrorCircleIcon />
          ) : (
            <CheckCircleIcon />
          )}
          An uppercase letter
        </span>
        <span className={getStatusItemClassNames(formStatus[LOWERCASE_LETTER])}>
          {formStatus[LOWERCASE_LETTER] === RED ? (
            <ErrorCircleIcon />
          ) : (
            <CheckCircleIcon />
          )}
          A lowercase letter
        </span>
        <span
          className={getStatusItemClassNames(formStatus[SPECIAL_CHARACTER])}
        >
          {formStatus[SPECIAL_CHARACTER] === RED ? (
            <ErrorCircleIcon />
          ) : (
            <CheckCircleIcon />
          )}
          A special character{' '}
          <span className="create-password-form__status__item__sub-text">
            (e.g. !@#$%)
          </span>
        </span>
        <span className={getStatusItemClassNames(formStatus[MISMATCH])}>
          {formStatus[MISMATCH] === RED ? (
            <ErrorCircleIcon />
          ) : (
            <CheckCircleIcon />
          )}
          Passwords must match
        </span>
      </div>
      {(resError || errorMessage) && (
        <ErrorBanner
          inputFieldKey="login"
          error={resError || errorMessage}
          AriaMessage={getAriaAlertMessage(formStatus)}
        />
      )}
      <form className="create-password-form__form" onSubmit={handleSubmit}>
        <PasswordInputField
          inputFieldKey={PASSWORD_FIELD}
          labelText={isFirstPassword ? 'Password' : 'New password'}
          inputPlaceholder="Password"
          value={password}
          onChange={handlePasswordChange}
        />
        <PasswordInputField
          inputFieldKey={CONFIRM_PASSWORD_FIELD}
          labelText={
            isFirstPassword ? 'Confirm password' : 'Confirm new password'
          }
          inputPlaceholder="Password"
          value={confirmPassword}
          onChange={handleConfirmPasswordChange}
        />
        <Button
          inputFieldKey="submit"
          className="create-password-form__form__button"
          text={isFirstPassword ? 'Create an account' : 'Reset password'}
          type="submit"
          isPrimary
        />
      </form>
    </div>
  );
};

export default CreatePasswordForm;
