import {useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import {globalUIContext} from '../../utils/global-ui-helpers';
import BillingForm from '../../components/billing-form';
import PlanSummary from '../../components/plan-summary';
import PlanSummaryMobile from '../../components/plan-summary-mobile';
import {PRO_PLAN_NAME} from '../../constants/plan';

const ProUpgradePage = () => {
  const {isMobile} = useContext(globalUIContext);
  const navigate = useNavigate();
  if (!window.userEmail) {
    return navigate('/');
  }

  return (
    <>
      <BillingForm email={window.userEmail} enableProgressIcon={false} />
      {isMobile ? (
        <PlanSummaryMobile planType={PRO_PLAN_NAME} />
      ) : (
        <PlanSummary planType={PRO_PLAN_NAME} />
      )}
    </>
  );
};
export default ProUpgradePage;
