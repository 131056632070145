// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-form {
  display: flex;
  flex-direction: column;
  width: 304px;
}
.login-form__heading {
  width: 100%;
  text-align: center;
  font-weight: 300;
  line-height: 125%;
  /* 30px */
  margin-bottom: 40px;
}
.login-form .password-input-field {
  margin-top: 24px;
}
.login-form__row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.login-form__remember-me {
  display: flex;
  align-items: center;
}
.login-form__remember-me input {
  height: 16px;
  width: 16px;
  margin: 0 8px 0 0;
  border-color: #9a9fa6;
}
.login-form__remember-me label {
  font-size: 14px;
  font-weight: 300;
}
.login-form__forget-pwd {
  font-size: 14px;
  color: #006de9;
  text-decoration: none;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/login-form/index.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;AADF;AAGE;EACE,WAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EADF,SAAS;EAEP,mBAAA;AAAJ;AAVA;EAcI,gBAAA;AADJ;AAIE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;EACA,mBAAA;AAFJ;AAKE;EACE,aAAA;EACA,mBAAA;AAHJ;AACE;EAII,YAAA;EACA,WAAA;EACA,iBAAA;EACA,qBAAA;AAFN;AALE;EAUI,eAAA;EACA,gBAAA;AAFN;AAME;EACE,eAAA;EACA,cAAA;EACA,qBAAA;EACA,eAAA;AAJJ","sourcesContent":["@import '../../style/colors.less';\n\n.login-form {\n  display: flex;\n  flex-direction: column;\n  width: 304px;\n\n  &__heading {\n    width: 100%;\n    text-align: center;\n    font-weight: 300;\n    line-height: 125%; /* 30px */\n    margin-bottom: 40px;\n  }\n\n  .password-input-field {\n    margin-top: 24px;\n  }\n\n  &__row {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    margin-top: 20px;\n    margin-bottom: 20px;\n  }\n\n  &__remember-me {\n    display: flex;\n    align-items: center;\n    input {\n      height: 16px;\n      width: 16px;\n      margin: 0 8px 0 0;\n      border-color: @BgBorderNeutral30;\n    }\n    label {\n      font-size: 14px;\n      font-weight: 300;\n    }\n  }\n\n  &__forget-pwd {\n    font-size: 14px;\n    color: @BgBorderBrand;\n    text-decoration: none;\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
