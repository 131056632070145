export const SOCIAL_TABLES_TITLE = 'Social Tables';
export const CONFIRM_PLAN_TITLE = 'Confirm Plan';
export const CREATE_AN_ACCOUNT_TITLE = 'Create an Account';
export const UPGRADE_ACCOUNT_TITLE = 'Upgrade Account';
export const CREATE_AN_ACCOUNT_OR_UPGRADE_TITLE =
  'Create an Account or Upgrade';
export const FORGOT_PASSWORD_TITLE = 'Reset your password';
export const CREATE_PASSWORD_TITLE = 'Create Password';
export const BILLING_INFORMATION_TITLE = 'Billing Information';
export const ACCOUNT_CREATION_SUCCESS_TITLE =
  "All set! You've created an account";
export const PRO_SIGNUP_SUCCESS_TITLE = "All set! You've signed up for Pro";
