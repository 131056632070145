import {useContext, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {globalUIContext} from '../../utils/global-ui-helpers';
import PlanPicker from '../../components/plan-picker';
import PlanSummary from '../../components/plan-summary';
import PlanSummaryMobile from '../../components/plan-summary-mobile';
import {CONFIRM_PLAN_TITLE} from '../../constants/page-title';
import {updatePageTitle} from '../../utils/page-title-helpers';
import './index.less';

const PlanSelectionPage = () => {
  const {isMobile, selectedPlanType, setFreePlanType, setProPlanType} =
    useContext(globalUIContext);
  const navigate = useNavigate();

  useEffect(() => {
    updatePageTitle(CONFIRM_PLAN_TITLE);
  }, []);

  return (
    <div className="plan-selection-page">
      <PlanPicker
        selectedPlanType={selectedPlanType}
        setFreePlanType={setFreePlanType}
        setProPlanType={setProPlanType}
        handleNextStep={() => navigate('/signup/form')}
      />
      {isMobile ? (
        <PlanSummaryMobile planType={selectedPlanType} />
      ) : (
        <PlanSummary planType={selectedPlanType} />
      )}
    </div>
  );
};
export default PlanSelectionPage;
