import {useState, useEffect, useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import InputField from '../shared/input-field';
import PasswordInputField from '../shared/password-input-field';
import Button from '../shared/button';
import ErrorBanner from '../shared/error-banner';
import {EMAIL_FIELD, PASSWORD_FIELD} from '../../constants/form-fields';
import {getQueryString} from '../../utils/url-helpers';
import {validateEmail} from '../../utils/validate-helpers';
import {focusField} from '../../utils/form-helpers';
import './index.less';
import {
  UNAUTHORIZED_CODE,
  UNAUTHORIZED_TEXT,
  GENERIC_LOGIN_ERROR_TEXT,
  REQUIRED_FORM_FIELD_TEXT,
  ENTER_VALID_EMAIL_TEXT,
  LOCKED_CODE,
  SUSPENDED_CODE,
  PRO_SUBSCRIPTION_ENDED,
} from '../../constants/errors';
import {globalUIContext} from '../../utils/global-ui-helpers';
import {
  SUSPENDED_MODAL,
  INCOMPLETE_BILLING_MODAL,
  REACTIVATE_ACCOUNT_MODAL,
} from '../../constants/modals';

const LOGIN_FORM_ORDER = [EMAIL_FIELD, PASSWORD_FIELD];
const LOCKED_TIME = 10 * 60 * 1000; // 10 minutes in milliseconds

const LoginForm = ({isShortVersion = false}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setErrors] = useState('');
  const [fieldErrors, setFieldErrors] = useState({});
  const [lockedTime, setLockedTime] = useState(0); // Track the remaining locked time
  const navigate = useNavigate();
  const {openModal} = useContext(globalUIContext);

  useEffect(() => {
    if (Object.keys(fieldErrors).length) {
      focusField(fieldErrors, LOGIN_FORM_ORDER);
    }
  }, [fieldErrors]);

  useEffect(() => {
    if (lockedTime > 0) {
      const timer = setInterval(() => {
        setLockedTime((prevLockedTime) => prevLockedTime - 1000);
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [lockedTime]);

  const validFields = () => {
    const errors = {};
    if (!email) {
      errors[EMAIL_FIELD] = REQUIRED_FORM_FIELD_TEXT;
    } else if (!validateEmail(email)) {
      errors[EMAIL_FIELD] = ENTER_VALID_EMAIL_TEXT;
    }
    if (!password) {
      errors[PASSWORD_FIELD] = REQUIRED_FORM_FIELD_TEXT;
    }
    setFieldErrors(errors);
    return !Object.keys(errors).length;
  };

  const resetErrors = () => {
    setErrors('');
    setFieldErrors({});
  };

  const loginRequest = async () => {
    try {
      if (lockedTime > 0) {
        return;
      }
      resetErrors();
      if (!validFields()) {
        return;
      }
      const redirect = getQueryString('redirect');
      const body = {
        email,
        password,
        _csrf: window.csrfToken || '',
        ...(redirect && {redirect}),
      };
      const response = await fetch('/login', {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          'X-Forwarded-Proto': 'https',
        },
        body: JSON.stringify(body),
      }).then((res) => res.json());
      if (response?.message === LOCKED_CODE) {
        return setLockedTime(LOCKED_TIME);
      }
      if (response?.message === SUSPENDED_CODE) {
        return openModal(SUSPENDED_MODAL);
      }
      if (response?.message === PRO_SUBSCRIPTION_ENDED) {
        return openModal(REACTIVATE_ACCOUNT_MODAL);
      }
      if (response?.code == UNAUTHORIZED_CODE) {
        return setErrors(UNAUTHORIZED_TEXT);
      }
      if (!response.user) {
        return setErrors(GENERIC_LOGIN_ERROR_TEXT);
      }
      if (response?.hasIncompleteBilling) {
        return openModal(INCOMPLETE_BILLING_MODAL, {
          redirectUrl: response.redirectURL || window.homeUrl,
          canClose: false,
        });
      }
      if (isShortVersion) {
        return (window.location = `${window.location.origin}/pro/upgrade`);
      }
      window.location = response.redirectURL || window.homeUrl;
    } catch (error) {
      setErrors(GENERIC_LOGIN_ERROR_TEXT);
    }
  };

  const formatLockedTime = (lockedTime) => {
    const minutes = Math.floor(lockedTime / 60000);
    const seconds = Math.floor((lockedTime % 60000) / 1000);
    return `${minutes} min ${seconds} sec`;
  };

  return (
    <form className="login-form" onSubmit={loginRequest}>
      {!isShortVersion && (
        <h2 className="login-form__heading">Bring your events to life</h2>
      )}
      <InputField
        inputFieldKey={EMAIL_FIELD}
        labelText="Email"
        inputPlaceholder="name@companyname.com"
        value={email}
        onChange={(value) => {
          resetErrors();
          setEmail(value);
        }}
        onEnter={loginRequest}
        ariaLabel="Enter your email."
        error={fieldErrors[EMAIL_FIELD]}
      />
      <PasswordInputField
        inputFieldKey={PASSWORD_FIELD}
        labelText="Password"
        inputPlaceholder="Password"
        value={password}
        onChange={(value) => {
          resetErrors();
          setPassword(value);
        }}
        onEnter={loginRequest}
        error={fieldErrors[PASSWORD_FIELD]}
      />
      <ErrorBanner
        inputFieldKey="login"
        error={
          lockedTime > 0
            ? `Due to multiple password attempts, your account has been locked for ${formatLockedTime(
                lockedTime
              )}.`
            : error
        }
      />
      <div className="login-form__row">
        <a
          id="button-forgort_password"
          className="login-form__forget-pwd"
          aria-label="forgot password"
          role="link"
          href="/forgot-password"
        >
          Forgot password?
        </a>
      </div>
      <Button
        inputFieldKey="login"
        text="Log in"
        isPrimary
        onClick={loginRequest}
        isDisabled={lockedTime > 0}
      />
      {!isShortVersion && (
        <div className="separator">Don't have an account?</div>
      )}
      {!isShortVersion && (
        <Button
          inputFieldKey="create_an_account"
          text="Create an account"
          isPrimary={false}
          onClick={() => navigate('/signup')}
        />
      )}
    </form>
  );
};

export default LoginForm;
