import Button from '../../shared/button';
import './index.less';
import {PRO_BASE_PRICE, PRO_PLAN_DETAILS} from '../../../constants/plan';
import {LogOutIcon} from '../../shared/icons/';
import {useNavigate} from 'react-router-dom';

const ReactivateAccountModal = ({handleClose, initialFocus}) => {
  const navigate = useNavigate();

  const logoutRequest = async () => {
    try {
      await fetch('/logout', {
        method: 'GET',
        credentials: 'same-origin',
      }).then((res) => {
        if (res.ok || res.status === 303) {
          window.location.href = res.url || '/';
        } else {
          handleClose();
          return navigate('/failure-general');
        }
      });
    } catch (error) {
      handleClose();
      return navigate('/failure-general');
    }
  };

  return (
    <div className="reactivate-account-modal">
      <div
        className="reactivate-account-modal__header"
        ref={initialFocus}
        tabIndex="-1"
      >
        <h2>Welcome back!</h2>
      </div>
      <div className="reactivate-account-modal__content">
        <div className="reactivate-account-modal__content__message">
          <p>
            It looks like your account has been archived, but no worries - let's
            get you back in action!
          </p>
          <p>
            By unlocking PRO, you will regain access to all of its features:
          </p>
        </div>

        <div className="reactivate-account-modal__content__plan-detail">
          <div
            className={
              'reactivate-account-modal__content__plan-detail__main reactivate-account-modal__content__plan-detail__main-pro'
            }
          >
            <div
              className={
                'reactivate-account-modal__content__plan-detail__plan reactivate-account-modal__content__plan-detail__plan-pro'
              }
            >
              PRO plan
              <div>{`$${PRO_BASE_PRICE}/MONTH`}</div>
            </div>
            <div
              className={
                'reactivate-account-modal__content__plan-detail__limit reactivate-account-modal__content__plan-detail__limit-pro'
              }
            >
              {PRO_PLAN_DETAILS &&
                PRO_PLAN_DETAILS.map((detail, index) => (
                  <div key={index}>{detail}</div>
                ))}
            </div>
          </div>
        </div>

        <div className="reactivate-account-modal__content__contact">
          <p>
            For any additional questions, contact our support team,{' '}
            <a
              aria-label="socialtables support email link"
              href="mailto:ST.support@cvent.com"
            >
              ST.support@cvent.com
            </a>
            , or call 866-318-4357. Phone support is available Monday - Friday,
            9 am - 10 pm ET.
          </p>
        </div>
      </div>
      <div className="reactivate-account-modal__actions">
        <Button
          inputFieldKey="log_out"
          className="reactivate-account-modal__actions__log-out-btn"
          text={
            <div className="reactivate-account-modal__actions__log-out-btn__content">
              <span>Log out</span>
              <LogOutIcon />
            </div>
          }
          onClick={logoutRequest}
          type="button"
          ariaLabel="Log out"
          isPrimary={false}
        />
        <Button
          inputFieldKey="reactivate_account"
          className="reactivate-account-modal__actions__reactivate-account-btn"
          text="Reactivate your account"
          onClick={() => {
            window.location.href = `${window.location.origin}/pro/upgrade`;
          }}
          type="button"
          isPrimary={true}
        />
      </div>
    </div>
  );
};

export default ReactivateAccountModal;
