import Button from '../../shared/button';
import SpotError from '../../../assets/spot-error.svg';
import {HAS_INCOMPLETE_BILLING} from '../../../constants/settings';
import './index.less';

const IncompleteBillingModal = ({redirectUrl, initialFocus}) => {
  const handleStartFreePlan = () => {
    fetch(`/settings/team`, {
      method: 'PATCH',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        'X-Forwarded-Proto': 'https',
      },
      body: JSON.stringify({
        [HAS_INCOMPLETE_BILLING]: false,
        _csrf: window.csrfToken || '',
      }),
    });
    window.location.href = redirectUrl;
  };
  return (
    <div className="incomplete-billing-modal">
      <div className="incomplete-billing-modal__header">
        <img
          className="incomplete-billing-modal__header__image"
          src={SpotError}
          alt="ErrorImage"
          aria-hidden="true"
        />
        <h1 ref={initialFocus} tabIndex="-1">
          Finish signing up for PRO?
        </h1>
      </div>
      <div className="incomplete-billing-modal__content">
        <p>You'll continue where you left off.</p>
      </div>
      <div className="incomplete-billing-modal__actions">
        <Button
          inputFieldKey="start-free-plan"
          text="Start Free plan"
          isPrimary={false}
          onClick={handleStartFreePlan}
        />
        <Button
          inputFieldKey="get-pro"
          text="Get PRO"
          isPrimary={true}
          onClick={() => {
            window.location.href = `${window.location.origin}/pro/upgrade`;
          }}
        />
      </div>
    </div>
  );
};

export default IncompleteBillingModal;
