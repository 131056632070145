import {useState} from 'react';
import classNames from 'classnames';
import {handleOnEnter} from '../../../utils/keyboard-helpers';
import {EyesIcon, ClosedEyesIcon, AlertTriangle} from '../icons/index';

import './index.less';

const PasswordInputField = ({
  inputFieldKey = 'password',
  labelText = 'Password',
  value,
  onChange = () => {},
  onEnter = () => {},
  error = '',
}) => {
  const [showPwd, setShowPwd] = useState(false);

  const onChangePwd = (e) => {
    e.preventDefault();
    onChange(e.target.value);
  };

  const toggleShowPwd = (e) => {
    e.preventDefault();
    setShowPwd(!showPwd);
  };

  const inputClassNames = classNames(
    'password-input-field__input',
    error ? `password-input-field__input-error` : null
  );
  const errorFieldId = `field-error-${inputFieldKey}`;
  return (
    <div className="password-input-field">
      <label htmlFor={`field-${inputFieldKey}`}>{labelText}</label>
      <input
        id={`field-${inputFieldKey}`}
        aria-describedby={errorFieldId}
        aria-invalid={!!error}
        className={inputClassNames}
        type={showPwd ? 'text' : 'password'}
        value={value}
        placeholder="Password"
        onChange={onChangePwd}
        onKeyDown={handleOnEnter(onEnter)}
      />
      <div
        id={`button-toggle-display-${inputFieldKey}`}
        className="password-input-field__eye-icon"
        role="button"
        aria-label={showPwd ? 'hide password' : 'display password'}
        onClick={toggleShowPwd}
        onKeyDown={handleOnEnter(toggleShowPwd)}
        tabIndex={0}
      >
        {showPwd ? <EyesIcon /> : <ClosedEyesIcon />}
      </div>
      {error && (
        <div className="input-field__error">
          <AlertTriangle />{' '}
          <span
            id={errorFieldId}
            role="alert"
            aria-label={`${labelText} field error`}
          >
            {error}
          </span>
        </div>
      )}
    </div>
  );
};

export default PasswordInputField;
