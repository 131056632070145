// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.failure-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.failure-page__image {
  height: 400px;
  width: 400px;
}
.failure-page__heading {
  font-size: 24px;
  line-height: 125%;
  font-weight: 300;
}
.failure-page__message {
  margin-top: 16px;
  font-size: 16px;
  max-width: 450px;
  text-align: center;
  line-height: 24px;
  color: #69717a;
}
.failure-page__button {
  margin: 16px 0;
  min-width: 90px;
}
@media (max-width: 1080px) {
  .signup-success {
    width: 304px;
  }
  .signup-success__image {
    width: 304px;
  }
  .signup-success__heading {
    margin-top: 0;
    font-size: 18px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/routes/failure-page/index.less"],"names":[],"mappings":"AAGA;EACE,WAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;AAFF;AAGE;EACE,aAAA;EACA,YAAA;AADJ;AAGE;EACE,eAAA;EACA,iBAAA;EACA,gBAAA;AADJ;AAGE;EACE,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,iBAAA;EACA,cAAA;AADJ;AAGE;EACE,cAAA;EACA,eAAA;AADJ;AAKA;EACE;IACE,YAAA;EAHF;EAIE;IACE,YAAA;EAFJ;EAIE;IACE,aAAA;IACA,eAAA;EAFJ;AACF","sourcesContent":["@import '../../style/colors.less';\n@import '../../style/mobile.less';\n\n.failure-page {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  &__image {\n    height: 400px;\n    width: 400px;\n  }\n  &__heading {\n    font-size: 24px;\n    line-height: 125%;\n    font-weight: 300;\n  }\n  &__message {\n    margin-top: 16px;\n    font-size: 16px;\n    max-width: 450px;\n    text-align: center;\n    line-height: 24px;\n    color: @TextIconInteractive-soft;\n  }\n  &__button {\n    margin: 16px 0;\n    min-width: 90px;\n  }\n}\n\n@media (max-width: @MobileMaxWidth) {\n  .signup-success {\n    width: @MobileFormWidth;\n    &__image {\n      width: @MobileFormWidth;\n    }\n    &__heading {\n      margin-top: 0;\n      font-size: 18px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
