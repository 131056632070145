export const updatePageTitle = (newTitle) => {
  if (newTitle && document.title !== newTitle) {
    document.title = newTitle;
    const announcer = document.getElementById('screen-reader-announcer');
    if (announcer) {
      announcer.textContent = `Navigated to ${newTitle} page`;
    }
  }
  return;
};
