import {useContext} from 'react';
import {globalUIContext} from '../../utils/global-ui-helpers';
import BillingForm from '../../components/billing-form';
import PlanSummary from '../../components/plan-summary';
import PlanSummaryMobile from '../../components/plan-summary-mobile';
import {PRO_PLAN_NAME} from '../../constants/plan';

const BillingPage = () => {
  const {isMobile, email} = useContext(globalUIContext);
  return (
    <>
      <BillingForm email={email} />
      {isMobile ? (
        <PlanSummaryMobile planType={PRO_PLAN_NAME} />
      ) : (
        <PlanSummary planType={PRO_PLAN_NAME} />
      )}
    </>
  );
};
export default BillingPage;
