import Button from '../../shared/button';
import './index.less';
import FocusTrap from 'focus-trap-react';

const EmailSentModal = ({handleClose, initialFocus}) => {
  return (
    <div
      className="email-sent-modal"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal_header"
      aria-describedby="modal_content"
    >
      <div
        className="email-sent-modal__header"
        tabIndex="-1"
        ref={initialFocus}
      >
        <h1 id="modal_header">Success!</h1>
      </div>
      <div className="email-sent-modal__content">
        <p id="modal_content">
          Email has been sent. Check your email address for more instructions.
        </p>
        <p className="screen-reader-only">
          You can resend the email in 2 minutes or go to login by navigating to
          the buttons below.
        </p>
      </div>
      <div className="email-sent-modal__actions">
        <Button
          inputFieldKey="resend_email"
          className="email-sent-modal__actions__resend-btn"
          text="Resend email"
          onClick={handleClose}
          type="button"
          isPrimary={false}
        />
        <Button
          inputFieldKey="go_to_login"
          className="email-sent-modal__actions__go-to-login-btn"
          text="Go to login"
          onClick={() => (window.location = '/')}
          type="button"
          isPrimary={true}
        />
      </div>
    </div>
  );
};

export default EmailSentModal;
