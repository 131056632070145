import {useNavigate} from 'react-router-dom';
import checkmark from '../../assets/checkmark.svg';
import Button from '../../components/shared/button';
import './index.less';

const ResetPasswordSuccessPage = () => {
  const navigate = useNavigate();

  return (
    <div
      className="reset-password-success-page"
      role="status"
      aria-labelledby="#reset-password-success-page__heading"
      aria-describedby="#reset-password-success-page__description"
      tabIndex="-1"
    >
      <img
        src={checkmark}
        className="reset-password-success-page__image"
        alt="success image"
        aria-hidden="true"
      />
      <h3 id="reset-password-success-page__heading">Success!</h3>
      <p id="reset-password-success-page__description">
        Your password has been reset.
      </p>
      <Button
        inputFieldKey="go_to_login"
        className="failure-page__button"
        text={'Go to login'}
        onClick={() => navigate('/login')}
      />
    </div>
  );
};

export default ResetPasswordSuccessPage;
