import {useEffect, useState, useCallback} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import TeamMemberSignupForm from '../../components/team-member-signup-form';
import CreatePasswordForm from '../../components/create-password-form';
import FormHeader from '../../components/shared/form-header';
import {TeamIcon} from '../../components/shared/icons';
import {deconstructInvitationCode} from '../../utils/url-helpers';
import {CREATE_PASSWORD_TITLE} from '../../constants/page-title';

import './index.less';

const JoinTeamPage = () => {
  const navigate = useNavigate();
  const {invitationCode} = useParams();
  const {id: inviteId} = deconstructInvitationCode(invitationCode);
  const [loading, setLoading] = useState(true);
  const [teamMemberData, setTeamMemberData] = useState(null);
  const [isPasswordStep, setIsPasswordStep] = useState(false);
  const [invitationData, setInvitationData] = useState({});
  const [resError, setResError] = useState(null);

  useEffect(() => {
    const fetchInvitationData = async () => {
      try {
        const response = await fetch(`/invitation/team/${inviteId}`, {
          method: 'GET',
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json',
            'X-Forwarded-Proto': 'https',
          },
        }).then((res) => res.json());
        setInvitationData(response);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    fetchInvitationData();
  }, []);

  const handleResetPassword = useCallback(
    async ({password, confirmPassword}) => {
      try {
        setResError(null);
        const response = await fetch('/register-team-member', {
          method: 'POST',
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json',
            'X-Forwarded-Proto': 'https',
          },
          body: JSON.stringify({
            ...teamMemberData,
            // overwrite inviteId, should replace and remove after.
            invitationCode: invitationCode,
            password,
            confirm_password: confirmPassword,
            _csrf: window.csrfToken || '',
          }),
        }).then((res) => res.json());
        if (response.message || !response.user) {
          return setResError(response.message);
        }
        return navigate('/success');
      } catch (error) {
        return navigate('/failure-general');
      }
    }
  );

  if (loading) {
    return;
  }

  if (isPasswordStep) {
    return (
      <div className="join-team-page__set-password">
        <FormHeader current={2} max={2} headerText={CREATE_PASSWORD_TITLE} />
        <CreatePasswordForm
          handleResetPassword={handleResetPassword}
          isFirstPassword={true}
          resError={resError}
          setResError={setResError}
        />
      </div>
    );
  }

  return (
    <div className="join-team-page">
      <p>
        <span className="bold">{invitationData?.inviter?.name}</span> (
        {invitationData?.inviter?.email}) has invited you to join their
        diagramming team:
      </p>
      <div className="join-team-page__team-name">
        <TeamIcon />
        <span className="bold">{invitationData?.existingTeam?.name}</span>
      </div>
      <TeamMemberSignupForm
        email={invitationData?.invitation?.email}
        invitationId={invitationData?.invitation?.id}
        setBody={(value) => {
          setTeamMemberData(value);
          setIsPasswordStep(true);
        }}
        isLegacy={false}
      />
    </div>
  );
};

export default JoinTeamPage;
