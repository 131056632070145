// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.earth-portal-modal {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.earth-portal-modal__backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.3;
}
.earth-portal-modal__container {
  position: relative;
  background: white;
  margin: 10% auto;
  width: fit-content;
  border-radius: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/index.less"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,aAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,cAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AADF;AAGE;EACE,eAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;EACA,YAAA;AADJ;AAIE;EACE,kBAAA;EACA,iBAAA;EACA,gBAAA;EACA,kBAAA;EACA,mBAAA;AAFJ","sourcesContent":["@import '../../style/colors.less';\n\n.earth-portal-modal {\n  position: fixed;\n  z-index: 9999;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  overflow: auto;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  &__backdrop {\n    position: fixed;\n    width: 100%;\n    height: 100%;\n    background: black;\n    opacity: 0.3;\n  }\n\n  &__container {\n    position: relative;\n    background: white;\n    margin: 10% auto;\n    width: fit-content;\n    border-radius: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
