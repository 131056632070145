import {createContext, useState, useEffect} from 'react';
import {FREE_PLAN_NAME, PRO_PLAN_NAME} from '../constants/plan';
import {getSourceSite} from './url-helpers';

const WIDTH_BREAK = 1080;
const LOCAL_SELECTED_PLAN_TYPE = 'LOCAL_SELECTED_PLAN_TYPE';

const DEFAULT_GLOBAL_UI = {
  isMobile: window.innerWidth <= WIDTH_BREAK,
  selectedPlanType:
    localStorage.getItem(LOCAL_SELECTED_PLAN_TYPE) || FREE_PLAN_NAME,
  email: null,
  invitationId: null,
  body: null,
  redirectUrl: null,
  isCollaborator: false,
  sourceSite: null,
  modal: {
    modalName: null,
    modalProps: {
      canClose: true,
    },
  },
};

export const globalUIContext = createContext(DEFAULT_GLOBAL_UI);

export const useGlobalUI = () => {
  const [isMobile, setIsMobile] = useState(DEFAULT_GLOBAL_UI.isMobile);
  const [selectedPlanType, setSelectedPlanType] = useState(
    DEFAULT_GLOBAL_UI.selectedPlanType
  );
  const [email, setEmail] = useState(DEFAULT_GLOBAL_UI.email);
  const [invitationId, setInvitationId] = useState(
    DEFAULT_GLOBAL_UI.invitationId
  );
  const [body, setBody] = useState(DEFAULT_GLOBAL_UI.invitationId);
  const [modal, setModal] = useState(DEFAULT_GLOBAL_UI.modal);
  const [redirectUrl, setRedirectUrl] = useState(DEFAULT_GLOBAL_UI.redirectUrl);
  const [sourceSite, setSourceSite] = useState(DEFAULT_GLOBAL_UI.sourceSite);
  const [isCollaborator, setIsCollaborator] = useState(
    DEFAULT_GLOBAL_UI.isCollaborator
  );

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= WIDTH_BREAK);
    }
    window.addEventListener('resize', handleResize);
    setSourceSite(getSourceSite());
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    localStorage.setItem(LOCAL_SELECTED_PLAN_TYPE, selectedPlanType);
  }, [selectedPlanType]);

  const setFreePlanType = () => {
    setSelectedPlanType(FREE_PLAN_NAME);
  };

  const setProPlanType = () => {
    setSelectedPlanType(PRO_PLAN_NAME);
  };

  const openModal = (modalName, modalProps = {}) =>
    setModal({
      modalName,
      modalProps: {
        ...DEFAULT_GLOBAL_UI.modal.modalProps,
        ...modalProps,
      },
    });

  const closeModal = () => setModal(DEFAULT_GLOBAL_UI.modal);

  return {
    isMobile,
    selectedPlanType,
    setFreePlanType,
    setProPlanType,
    modal,
    setModal,
    openModal,
    closeModal,
    email,
    setGlobalEmail: setEmail,
    invitationId,
    setInvitationId,
    body,
    setBody,
    redirectUrl,
    setRedirectUrl,
    sourceSite,
    isCollaborator,
    setIsCollaborator,
  };
};
