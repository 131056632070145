const noop = () => {
  /* noop*/
};

export const handleOnEnter =
  (onEnter = noop) =>
  (target) => {
    if (target.key === 'Enter') {
      onEnter(target);
    }
  };
