import './index.less';

const SIZE = 64;
const THICKNESS = 6;
const CX = SIZE / 2;
const CY = SIZE / 2;
const R = SIZE / 2 - THICKNESS;
const ARC_LENGTH = 2 * Math.PI * R;

const CircularProgressBar = ({current = 1, max = 2}) => {
  const arcOffset = ARC_LENGTH * (current / max);
  return (
    <div
      className="circular-progress-bar"
      role="progressbar"
      aria-valuenow={current}
      aria-valuemin={0}
      aria-valuemax={max}
    >
      <svg className="circular-progress-bar__svg" height={SIZE} width={SIZE}>
        <circle
          className="circular-progress-bar__indication"
          cx={CX}
          cy={CY}
          r={R}
          strokeWidth={THICKNESS}
        />
        <circle
          className="circular-progress-bar__track"
          cx={CX}
          cy={CY}
          r={R}
          strokeWidth={THICKNESS}
          strokeDasharray={ARC_LENGTH}
          strokeDashoffset={arcOffset}
        />
      </svg>
      <div className="circular-progress-bar__label">
        {`${current} of ${max}`}
      </div>
    </div>
  );
};

export default CircularProgressBar;
