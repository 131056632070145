import {useContext} from 'react';
import {globalUIContext} from '../../utils/global-ui-helpers';
import {HELP_AND_SUPPORT_MODAL} from '../../constants/modals';
import {ExternalLink, PRIVACY_POLICY} from '../shared/external-link';
import './index.less';

const Footer = () => {
  const {openModal} = useContext(globalUIContext);
  return (
    <footer className="footer">
      <a
        href="#"
        onClick={() => openModal(HELP_AND_SUPPORT_MODAL)}
        aria-label="help and support link"
      >
        Help & Support
      </a>
      <ExternalLink type={PRIVACY_POLICY} />
      {window?.useOneTrust && (
        <a
          href="#"
          onClick={() => window?.OneTrust?.ToggleInfoDisplay?.()}
          aria-label="privacy preference center link"
        >
          Privacy Preference Center
        </a>
      )}
    </footer>
  );
};
export default Footer;
