// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-banner {
  display: flex;
  font-size: 14px;
  line-height: 21px;
  background-color: #fef5f3;
  border-radius: 10px;
  margin-top: 12px;
}
.error-banner svg {
  min-height: 24px;
  min-width: 24px;
  max-height: 24px;
  padding: 12px;
}
.error-banner span {
  padding: 12px 20px 12px 8px;
  align-self: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/shared/error-banner/index.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,eAAA;EACA,iBAAA;EACA,yBAAA;EACA,mBAAA;EACA,gBAAA;AADF;AALA;EAQI,gBAAA;EAEA,eAAA;EACA,gBAAA;EACA,aAAA;AADJ;AAXA;EAeI,2BAAA;EACA,kBAAA;AADJ","sourcesContent":["@import '../../../style/colors.less';\n\n.error-banner {\n  display: flex;\n  font-size: 14px;\n  line-height: 21px;\n  background-color: @TextIconInteractive-danger-bg;\n  border-radius: 10px;\n  margin-top: 12px;\n  svg {\n    min-height: 24px;\n    max-height: 24px;\n    min-width: 24px;\n    max-height: 24px;\n    padding: 12px;\n  }\n  span {\n    padding: 12px 20px 12px 8px;\n    align-self: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
