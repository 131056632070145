// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.plan-selection-page {
  position: relative;
  display: flex;
  margin-top: 60px;
}
.plan-selection-page__collaborator {
  position: absolute;
  top: -40px;
}
`, "",{"version":3,"sources":["webpack://./src/routes/plan-selection-page/index.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;EACA,gBAAA;AACF;AAAE;EACE,kBAAA;EACA,UAAA;AAEJ","sourcesContent":[".plan-selection-page {\n  position: relative;\n  display: flex;\n  margin-top: 60px;\n  &__collaborator {\n    position: absolute;\n    top: -40px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
