// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pro-page {
  width: 600px;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pro-page h1 {
  font-size: 24px;
  width: 100%;
  font-weight: 400;
  line-height: 36px;
  text-align: left;
  margin-left: 8px;
  margin-bottom: 16px;
  border-bottom: 1px solid #1a2026;
}
.pro-page__buttons {
  width: 300px;
  margin-top: 24px;
}
.pro-page__login {
  width: 300px;
}
.pro-page__login .breadcrumb {
  color: #69717a;
  font-size: 12px;
  line-height: 150%;
  display: flex;
  align-items: center;
  justify-content: left;
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 20px;
}
.pro-page__login .breadcrumb-icon {
  width: 16px;
  height: 16px;
}
@media (max-width: 1080px) {
  .pro-page {
    width: 304px;
  }
  .pro-page h2 {
    font-size: 14px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/routes/pro-page/index.less"],"names":[],"mappings":"AAIA;EACE,YAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;AAHF;AAFA;EAOI,eAAA;EACA,WAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,gBAAA;EACA,mBAAA;EACA,gCAAA;AAFJ;AAIE;EACE,YAAA;EACA,gBAAA;AAFJ;AAIE;EACE,YAAA;AAFJ;AACE;EAGI,cAAA;EACA,eAAA;EACA,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,qBAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;AADN;AAVE;EAeI,WAAA;EACA,YAAA;AAFN;AAOA;EACE;IACE,YAAA;EALF;EAIA;IAGI,eAAA;EAJJ;AACF","sourcesContent":["@import '../../style/colors.less';\n\n@import '../../style/mobile.less';\n\n.pro-page {\n  width: 600px;\n  margin-top: 80px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  h1 {\n    font-size: 24px;\n    width: 100%;\n    font-weight: 400;\n    line-height: 36px;\n    text-align: left;\n    margin-left: 8px;\n    margin-bottom: 16px;\n    border-bottom: 1px solid @BgBorderNeutral150;\n  }\n  &__buttons {\n    width: 300px;\n    margin-top: 24px;\n  }\n  &__login {\n    width: 300px;\n    .breadcrumb {\n      color: @TextIconInteractive-soft;\n      font-size: 12px;\n      line-height: 150%;\n      display: flex;\n      align-items: center;\n      justify-content: left;\n      cursor: pointer;\n      margin-top: 20px;\n      margin-bottom: 20px;\n    }\n\n    .breadcrumb-icon {\n      width: 16px;\n      height: 16px;\n    }\n  }\n}\n\n@media (max-width: @MobileMaxWidth) {\n  .pro-page {\n    width: @MobileFormWidth;\n    h2 {\n      font-size: 14px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
