import {CardElement} from '@stripe/react-stripe-js';
import {AlertTriangle} from '../../shared/icons';
import classNames from 'classnames';

const CreditCard = ({
  error = '',
  handleChange = () => {},
  setCardElement = () => {},
}) => {
  const cardElementClassNames = classNames('StripeElement', {
    'StripeElement--invalid': error,
  });
  const errorFieldId = `field-error-credit-card`;
  return (
    <div
      className="billing-form-container__form__credit-card"
      aria-describedby={errorFieldId}
    >
      <span className="billing-form-container__form__credit-card-required">
        *
      </span>
      <CardElement
        className={cardElementClassNames}
        id="stripe-card-element"
        onChange={handleChange}
        options={{
          hidePostalCode: true,
        }}
        onReady={(e) => setCardElement(e)}
      />
      {error && (
        <div
          id={errorFieldId}
          className="billing-form-container__form__credit-card__error-message"
          aria-label={`credit card field error`}
        >
          <AlertTriangle /> {error}
        </div>
      )}
    </div>
  );
};

export default CreditCard;
