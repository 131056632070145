// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.incomplete-billing-modal {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  min-width: 295px;
  font-family: Rubik;
  font-feature-settings: 'clig' off, 'liga' off;
  font-style: normal;
}
.incomplete-billing-modal__header {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 24px 32px 16px 32px;
}
.incomplete-billing-modal__header__image {
  width: 100%;
  height: 120px;
}
.incomplete-billing-modal__header h1 {
  margin-top: 16px;
  color: #1a2026;
  font-size: 24px;
  font-weight: 400;
  line-height: 125%;
}
.incomplete-billing-modal__content {
  margin: 0 32px 0 32px;
  color: #69717a;
  font-size: 14px;
}
.incomplete-billing-modal__content p {
  line-height: 150%;
}
.incomplete-billing-modal__actions {
  display: flex;
  justify-content: flex-end;
  margin: 16px 32px 24px 32px;
}
.incomplete-billing-modal__actions .button-container-primary {
  width: 110px;
  margin-left: 24px;
}
.incomplete-billing-modal__actions .button-container-secondary button {
  width: 158px;
  border: none;
  border-radius: none;
}
.incomplete-billing-modal__actions__close-btn {
  width: 89px;
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/incomplete-billing-modal/index.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,gBAAA;EACA,kBAAA;EACA,6CAAA;EAGA,kBAAA;AAHF;AAKE;EACE,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,qBAAA;EACA,2BAAA;AAHJ;AAKI;EACE,WAAA;EACA,aAAA;AAHN;AANE;EAaI,gBAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AAJN;AAQE;EACE,qBAAA;EACA,cAAA;EACA,eAAA;AANJ;AAGE;EAMI,iBAAA;AANN;AAUE;EACE,aAAA;EACA,yBAAA;EACA,2BAAA;AARJ;AAKE;EAMI,YAAA;EACA,iBAAA;AARN;AACE;EAYM,YAAA;EACA,YAAA;EACA,mBAAA;AAVR;AAcI;EACE,WAAA;AAZN","sourcesContent":["@import '../../../style/colors.less';\n\n.incomplete-billing-modal {\n  display: flex;\n  flex-direction: column;\n  max-width: 400px;\n  min-width: 295px;\n  font-family: Rubik;\n  font-feature-settings:\n    'clig' off,\n    'liga' off;\n  font-style: normal;\n\n  &__header {\n    text-align: center;\n    display: flex;\n    flex-direction: column;\n    align-items: flex-end;\n    margin: 24px 32px 16px 32px;\n\n    &__image {\n      width: 100%;\n      height: 120px;\n    }\n\n    h1 {\n      margin-top: 16px;\n      color: @TextIconInteractive-base;\n      font-size: 24px;\n      font-weight: 400;\n      line-height: 125%;\n    }\n  }\n\n  &__content {\n    margin: 0 32px 0 32px;\n    color: @TextIconInteractive-soft;\n    font-size: 14px;\n\n    p {\n      line-height: 150%;\n    }\n  }\n\n  &__actions {\n    display: flex;\n    justify-content: flex-end;\n    margin: 16px 32px 24px 32px;\n\n    .button-container-primary {\n      width: 110px;\n      margin-left: 24px;\n    }\n\n    .button-container-secondary {\n      button {\n        width: 158px;\n        border: none;\n        border-radius: none;\n      }\n    }\n\n    &__close-btn {\n      width: 89px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
