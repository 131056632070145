const ArrowDownIcon = () => (
  <svg
    width="14"
    height="8"
    viewBox="0 0 14 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.7 0.3C13.3 -0.1 12.7 -0.1 12.3 0.3L7 5.6L1.7 0.3C1.3 -0.1 0.7 -0.1 0.3 0.3C-0.1 0.7 -0.1 1.3 0.3 1.7L6.3 7.7C6.5 7.9 6.8 8 7 8C7.2 8 7.5 7.9 7.7 7.7L13.7 1.7C14.1 1.3 14.1 0.7 13.7 0.3Z"
      fill="#69717A"
    />
  </svg>
);

export default ArrowDownIcon;
