export const FREE_PLAN_NAME = 'free';
export const PRO_PLAN_NAME = 'pro';

export const FREE_BASE_PRICE = 0;
export const PRO_BASE_PRICE = 199;

export const FREE_PLAN_DETAILS = [
  '1 User account',
  '1 Event per year',
  '25 Attendees per attendee list',
  '5 Diagrams per event',
  '5 Collaborators per event',
];

export const PRO_PLAN_DETAILS = [
  '1 User account',
  '12 Events per year',
  '150 Attendees per attendee list',
  '5 Diagrams per event',
  'Unlimited collaborators',
];
