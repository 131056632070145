// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-field {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: 8px;
}
.input-field-uneditable {
  opacity: 40%;
}
.input-field__is-required {
  color: #db2c00;
  margin-right: 2px;
  margin-left: -8px;
}
.input-field label {
  color: #69717a;
  font-size: 16px;
  margin-bottom: 8px;
}
.input-field__input {
  border: 1px solid #69717a;
  border-radius: 6px;
  padding: 8px 16px;
  font-size: 16px;
  line-height: 24px;
}
.input-field__input-error {
  border: 1px solid #db2c00;
}
.input-field__sublabel {
  margin-top: 4px;
  font-size: 12px;
  line-height: 150%;
}
.input-field__error {
  top: 64px;
  display: flex;
  align-items: center;
  margin-top: 1px;
  margin-bottom: -19px;
  height: 18px;
  font-size: 12px;
  color: #db2c00;
}
.input-field__error svg {
  margin-right: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/components/shared/input-field/index.less"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,aAAA;EACA,sBAAA;EAEA,mBAAA;AAFF;AAIE;EACE,YAAA;AAFJ;AAKE;EACE,cAAA;EACA,iBAAA;EACA,iBAAA;AAHJ;AAXA;EAkBI,cAAA;EACA,eAAA;EACA,kBAAA;AAJJ;AAOE;EACE,yBAAA;EACA,kBAAA;EACA,iBAAA;EACA,eAAA;EACA,iBAAA;AALJ;AAOI;EACE,yBAAA;AALN;AASE;EACE,eAAA;EACA,eAAA;EACA,iBAAA;AAPJ;AAUE;EACE,SAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;EACA,oBAAA;EACA,YAAA;EACA,eAAA;EACA,cAAA;AARJ;AAAE;EAUI,iBAAA;AAPN","sourcesContent":["@import '../../../style/colors.less';\n\n.input-field {\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  // extra spacing for error message\n  padding-bottom: 8px;\n\n  &-uneditable {\n    opacity: 40%;\n  }\n\n  &__is-required {\n    color: @TextIconInteractive-danger;\n    margin-right: 2px;\n    margin-left: -8px;\n  }\n\n  label {\n    color: @TextIconInteractive-soft;\n    font-size: 16px;\n    margin-bottom: 8px;\n  }\n\n  &__input {\n    border: 1px solid @TextIconInteractive-soft;\n    border-radius: 6px;\n    padding: 8px 16px;\n    font-size: 16px;\n    line-height: 24px;\n\n    &-error {\n      border: 1px solid @TextIconInteractive-danger;\n    }\n  }\n\n  &__sublabel {\n    margin-top: 4px;\n    font-size: 12px;\n    line-height: 150%;\n  }\n\n  &__error {\n    top: 64px;\n    display: flex;\n    align-items: center;\n    margin-top: 1px;\n    margin-bottom: -19px;\n    height: 18px;\n    font-size: 12px;\n    color: @TextIconInteractive-danger;\n    svg {\n      margin-right: 4px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
