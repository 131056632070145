// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.oauth-authorize-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.oauth-authorize-form__image {
  height: 400px;
  width: 400px;
}
.oauth-authorize-form__heading {
  font-size: 24px;
  line-height: 125%;
  font-weight: 300;
}
.oauth-authorize-form__message {
  margin-top: 16px;
  font-size: 16px;
  max-width: 450px;
  text-align: center;
  line-height: 24px;
  color: #69717a;
}
.oauth-authorize-form__btn-container {
  display: flex;
  justify-content: center;
  margin: 16px 0;
  column-gap: 16px;
}
.oauth-authorize-form__button {
  min-width: 80px;
  min-height: 40px;
}
`, "",{"version":3,"sources":["webpack://./src/routes/oauth-authorize-form/index.less"],"names":[],"mappings":"AAGA;EACE,WAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;AAFF;AAGE;EACE,aAAA;EACA,YAAA;AADJ;AAGE;EACE,eAAA;EACA,iBAAA;EACA,gBAAA;AADJ;AAGE;EACE,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,iBAAA;EACA,cAAA;AADJ;AAGE;EACE,aAAA;EACA,uBAAA;EACA,cAAA;EACA,gBAAA;AADJ;AAGE;EACE,eAAA;EACA,gBAAA;AADJ","sourcesContent":["@import '../../style/colors.less';\n@import '../../style/mobile.less';\n\n.oauth-authorize-form {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  &__image {\n    height: 400px;\n    width: 400px;\n  }\n  &__heading {\n    font-size: 24px;\n    line-height: 125%;\n    font-weight: 300;\n  }\n  &__message {\n    margin-top: 16px;\n    font-size: 16px;\n    max-width: 450px;\n    text-align: center;\n    line-height: 24px;\n    color: @TextIconInteractive-soft;\n  }\n  &__btn-container {\n    display: flex;\n    justify-content: center;\n    margin: 16px 0;\n    column-gap: 16px;\n  }\n  &__button {\n    min-width: 80px;\n    min-height: 40px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
