// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reset-password-success-page {
  min-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10%;
}
.reset-password-success-page h3 {
  margin-top: 32px;
}
.reset-password-success-page p {
  margin-top: 16px;
}
.reset-password-success-page .button-container {
  margin-top: 40px;
  min-width: 300px;
}
@media (max-width: 1080px) {
  .reset-password-success-page {
    width: 304px;
    min-width: 304px;
    margin: 0px;
  }
  .reset-password-success-page .form-header {
    width: 304px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/routes/reset-password-success-page/index.less"],"names":[],"mappings":"AAEA;EACE,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,eAAA;AADF;AAJA;EAOI,gBAAA;AAAJ;AAPA;EAUI,gBAAA;AAAJ;AAVA;EAaI,gBAAA;EACA,gBAAA;AAAJ;AAIA;EACE;IACE,YAAA;IACA,gBAAA;IACA,WAAA;EAFF;EADA;IAKI,YAAA;EADJ;AACF","sourcesContent":["@import '../../style/mobile.less';\n\n.reset-password-success-page {\n  min-width: 600px;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin-top: 10%;\n  h3 {\n    margin-top: 32px;\n  }\n  p {\n    margin-top: 16px;\n  }\n  .button-container {\n    margin-top: 40px;\n    min-width: 300px;\n  }\n}\n\n@media (max-width: @MobileMaxWidth) {\n  .reset-password-success-page {\n    width: @MobileFormWidth;\n    min-width: @MobileFormWidth;\n    margin: 0px;\n    .form-header {\n      width: @MobileFormWidth;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
