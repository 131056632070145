const ClosedEyesIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.1552 5.48333C10.8014 5.3875 11.4475 5.29167 12.0014 5.29167C16.986 5.29167 20.2167 10.4667 21.1398 12C20.586 12.9583 20.0321 13.725 19.4783 14.3958C19.1091 14.7792 19.2014 15.3542 19.5706 15.7375C19.7552 15.9292 19.9398 16.025 20.2167 16.025C20.4937 16.025 20.6783 15.9292 20.8629 15.7375C21.6014 14.875 22.3398 13.8208 22.986 12.4792C23.1706 12.1917 23.1706 11.9042 22.986 11.6167C22.8014 11.2333 18.9244 3.375 12.0014 3.375C11.2629 3.375 10.6167 3.47083 9.97059 3.56667C9.41674 3.6625 9.04751 4.14167 9.13982 4.62083C9.23213 5.19583 9.69367 5.57917 10.1552 5.48333ZM22.8014 21.8708L2.49367 0.7875C2.12444 0.404167 1.57059 0.404167 1.20136 0.7875C0.832129 1.17083 0.832129 1.74583 1.20136 2.12917L5.17059 6.25C3.41674 7.78333 2.03213 9.60417 1.01674 11.5208C0.924436 11.8083 0.832129 12.0958 1.01674 12.3833C1.20136 12.7667 5.07828 20.625 12.0014 20.625C13.9398 20.5292 15.786 19.9542 17.2629 18.9L21.4167 23.2125C21.6014 23.4042 21.8783 23.5 22.0629 23.5C22.2475 23.5 22.5244 23.4042 22.7091 23.2125C23.1706 22.8292 23.1706 22.2542 22.8014 21.8708ZM10.1552 11.5208L12.4629 13.9167C12.3706 13.9167 12.186 13.9167 12.0014 14.0125C11.5398 14.0125 11.0783 13.8208 10.7091 13.4375C10.1552 12.8625 9.97059 12.0958 10.1552 11.5208ZM12.0014 18.7083C7.01674 18.7083 3.78597 13.5333 2.8629 12C3.78597 10.3708 4.98597 8.93333 6.4629 7.6875L8.8629 10.0833C7.93982 11.5208 8.12444 13.4375 9.41674 14.7792C10.0629 15.45 10.986 15.8333 11.9091 15.8333H12.0014C12.6475 15.8333 13.2937 15.6417 13.8475 15.2583L15.9706 17.4625C14.7706 18.2292 13.386 18.6125 12.0014 18.7083Z"
      fill="#69717A"
    />
  </svg>
);

export default ClosedEyesIcon;
