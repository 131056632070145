import {useContext, useEffect} from 'react';
import {globalUIContext} from '../../utils/global-ui-helpers';
import celebrate from '../../assets/celebrate.svg';
import Button from '../../components/shared/button';
import {ACCOUNT_CREATION_SUCCESS_TITLE} from '../../constants/page-title';
import {updatePageTitle} from '../../utils/page-title-helpers';

import './index.less';

const SuccessPage = () => {
  const {redirectUrl} = useContext(globalUIContext);

  useEffect(() => {
    updatePageTitle(ACCOUNT_CREATION_SUCCESS_TITLE);
  }, []);

  return (
    <div className="signup-success">
      <img
        src={celebrate}
        className="signup-success__image"
        alt="signup success image"
        aria-hidden="true"
      />
      <h1 className="signup-success__heading">
        All set! You've created an account
      </h1>
      <Button
        inputFieldKey="get_started"
        className="signup-success__button"
        text="Get started"
        onClick={() => {
          window.location = redirectUrl || window.homeUrl;
        }}
      />
      <span className="signup-success__footer">
        For help & support, contact customer service,{' '}
        <a
          href="mailto:st.support@cvent.com"
          aria-label="socialtables support email link"
          className="signup-success__footer__email"
        >
          ST.support@cvent.com
        </a>
      </span>
    </div>
  );
};

export default SuccessPage;
