import {useEffect, useContext, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {globalUIContext} from '../../utils/global-ui-helpers';
import {PRO_PLAN_NAME} from '../../constants/plan';
import Button from '../../components/shared/button';
import ChevronLeftIcon from '../../components/shared/icons/chevron-left';
import LoginForm from '../../components/login-form';
import PlanSummary from '../../components/plan-summary';
import PlanSummaryMobile from '../../components/plan-summary-mobile';
import {
  UPGRADE_ACCOUNT_TITLE,
  CREATE_AN_ACCOUNT_OR_UPGRADE_TITLE,
} from '../../constants/page-title';
import {updatePageTitle} from '../../utils/page-title-helpers';

import './index.less';

const SignupPage = () => {
  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useState(false);
  const {isMobile, setProPlanType} = useContext(globalUIContext);

  useEffect(() => {
    setProPlanType();
    if (isLogin) {
      updatePageTitle(UPGRADE_ACCOUNT_TITLE);
    } else {
      updatePageTitle(CREATE_AN_ACCOUNT_OR_UPGRADE_TITLE);
    }
  }, []);

  const getContent = () => {
    if (isLogin) {
      return (
        <div className="pro-page__login">
          <span
            id="button-back"
            className="breadcrumb"
            onClick={() => setIsLogin(!isLogin)}
            aria-label="Go back to the pro plan page"
            tabIndex={0}
          >
            <ChevronLeftIcon className="breadcrumb-icon" />
            Back
          </span>
          <LoginForm isShortVersion={true} />
        </div>
      );
    }
    return (
      <div className="pro-page__buttons">
        <Button
          inputFieldKey="create_an_account"
          text="Create an account"
          onClick={() => navigate('/signup/form')}
        />
        <div className="separator">Have an account?</div>
        <Button
          inputFieldKey="login"
          text="Log in"
          isPrimary={false}
          onClick={() => setIsLogin(true)}
        />
      </div>
    );
  };

  return (
    <>
      <div className="pro-page">
        <h1>
          {isLogin ? UPGRADE_ACCOUNT_TITLE : CREATE_AN_ACCOUNT_OR_UPGRADE_TITLE}
        </h1>
        {getContent()}
      </div>
      {isMobile ? (
        <PlanSummaryMobile planType={PRO_PLAN_NAME} />
      ) : (
        <PlanSummary planType={PRO_PLAN_NAME} />
      )}
    </>
  );
};

export default SignupPage;
