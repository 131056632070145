import {AlertCircle} from '../icons/index';
import './index.less';

const ErrorBanner = ({inputFieldKey, error = '', AriaMessage}) => {
  if (!error) {
    return;
  }
  return (
    <div className="error-banner">
      <AlertCircle />
      <span
        id={`error-banner-${inputFieldKey}`}
        role="alert"
        aria-label={AriaMessage || ''}
      >
        {error}
      </span>
    </div>
  );
};

export default ErrorBanner;
