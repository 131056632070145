import classNames from 'classnames';
import {AlertTriangle} from '../icons';
import {handleOnEnter} from '../../../utils/keyboard-helpers';
import {FIELD_AUTOCOMPLETE_MAP} from '../../../constants/form-fields';
import './index.less';

const InputField = ({
  inputFieldKey,
  labelText = 'label',
  value = '',
  onChange = () => {},
  onEnter = () => {},
  inputPlaceholder = 'place holder',
  subLabelText = '',
  error = '',
  isRequired = false,
  uneditable = false,
}) => {
  const fieldClassNames = classNames('input-field', {
    'input-field-uneditable': uneditable,
  });
  const inputClassNames = classNames('input-field__input', {
    'input-field__input-error': error,
  });
  const errorFieldId = `field-error-${inputFieldKey}`;
  return (
    <div className={fieldClassNames}>
      <label htmlFor={`field-${inputFieldKey}`}>
        {isRequired && <span className="input-field__is-required">*</span>}
        {labelText}
      </label>
      <input
        id={`field-${inputFieldKey}`}
        aria-describedby={errorFieldId}
        aria-invalid={!!error}
        value={value}
        className={inputClassNames}
        placeholder={inputPlaceholder}
        onChange={(e) => {
          e.preventDefault();
          onChange(e.target.value);
        }}
        onKeyDown={handleOnEnter(onEnter)}
        disabled={uneditable}
        aria-required={isRequired}
        autoComplete={FIELD_AUTOCOMPLETE_MAP[inputFieldKey] || 'off'}
      />
      {!error && subLabelText && (
        <label htmlFor={inputFieldKey} className="input-field__sublabel">
          {subLabelText}
        </label>
      )}
      {error && (
        <div className="input-field__error">
          <AlertTriangle />{' '}
          <span
            id={errorFieldId}
            role="alert"
            aria-label={`${labelText} field error`}
          >
            {error}
          </span>
        </div>
      )}
    </div>
  );
};

export default InputField;
