import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './index.less';

const Button = ({
  inputFieldKey,
  text,
  onClick,
  type = 'button',
  className,
  ariaLabel,
  isPrimary = true,
  isDisabled = false,
  name,
  value,
}) => {
  const buttonClassNames = classNames(
    'button-container',
    isPrimary ? `button-container-primary` : `button-container-secondary`,
    className
  );

  return (
    <div className={buttonClassNames}>
      <button
        id={`button-${inputFieldKey}`}
        aria-label={ariaLabel || text}
        type={type}
        onClick={onClick}
        disabled={isDisabled}
        name={name}
        value={value}
      >
        {text}
      </button>
    </div>
  );
};

Button.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  className: PropTypes.string,
  ariaLabel: PropTypes.string,
  isPrimary: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

export default Button;
