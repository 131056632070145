import {useCallback, useContext, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {globalUIContext} from '../../utils/global-ui-helpers';
import FormHeader from '../../components/shared/form-header';
import CreatePasswordForm from '../../components/create-password-form';
import {CREATE_PASSWORD_TITLE} from '../../constants/page-title';
import './index.less';

const TeamMemberConfirmPasswordPage = () => {
  const {body, redirectUrl} = useContext(globalUIContext);
  const [resError, setResError] = useState(null);
  const navigate = useNavigate();

  const handleResetPassword = useCallback(
    async ({password, confirmPassword}) => {
      try {
        setResError(null);
        const response = await fetch('/register-team-member', {
          method: 'POST',
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json',
            'X-Forwarded-Proto': 'https',
          },
          body: JSON.stringify({
            ...body,
            password,
            confirm_password: confirmPassword,
            redirectUrl,
            _csrf: window.csrfToken || '',
          }),
        }).then((res) => res.json());
        if (response.message || !response.user) {
          return setResError(response.message);
        }
        return navigate('/success');
      } catch (error) {
        return navigate('/failure-general');
      }
    }
  );

  return (
    <div className="team-member-confirm-password-page">
      <FormHeader current={2} max={2} headerText={CREATE_PASSWORD_TITLE} />
      <CreatePasswordForm
        handleResetPassword={handleResetPassword}
        isFirstPassword={true}
        resError={resError}
        setResError={setResError}
      />
    </div>
  );
};

export default TeamMemberConfirmPasswordPage;
