import {XIcon} from '../../shared/icons';
import SpotError from '../../../assets/spot-error.svg';
import Button from '../../shared/button';
import './index.less';

const PaymentErrorModal = ({handleClose, initialFocus}) => {
  return (
    <div className="payment-error-modal">
      <div className="payment-error-modal__header">
        <div className="payment-error-modal__header__x" onClick={handleClose}>
          <XIcon />
        </div>
        <img
          className="payment-error-modal__header__image"
          src={SpotError}
          alt="ErrorImage"
          aria-hidden="true"
        />
        <h1 ref={initialFocus} tabIndex="-1">
          Your payment has not gone through
        </h1>
      </div>
      <div className="payment-error-modal__content">
        <p>
          We had some trouble processing this request. Please verify your
          information and try submitting again. If this issue keeps happening,
          email{' '}
          <a
            aria-label="socialtables support email link"
            href="mailto:ST.support@cvent.com"
          >
            ST.support@cvent.com
          </a>
          .
        </p>
      </div>
      <div className="payment-error-modal__actions">
        <Button
          inputFieldKey="try_again"
          className="payment-error-modal__actions__close-btn"
          text="Try again"
          onClick={handleClose}
          type="button"
          isPrimary={false}
        />
      </div>
    </div>
  );
};

export default PaymentErrorModal;
