import {useState} from 'react';
import classNames from 'classnames';
import {FREE_PLAN_NAME, PRO_PLAN_NAME} from '../../constants/plan';
import {ArrowDownIcon} from '../shared/icons';
import {PlanDetail, PriceDetails} from '../plan-summary';
import {getOneMonthFromToday} from '../../utils/date-helpers';
import './index.less';

const PlanSummaryMobile = ({planType, tax}) => {
  if (!planType) {
    return;
  }

  const [isOpen, setIsOpen] = useState(false);
  const isFree = planType === FREE_PLAN_NAME;
  const titleText = isFree ? (
    <>
      You are currently creating a <span className="bold">Free Trial</span>{' '}
      account
    </>
  ) : (
    <>
      You are currently purchasing <span className="bold">PRO Plan</span>
    </>
  );

  const containerClassNames = classNames('plan-summary-mobile', {
    'plan-summary-mobile-free': isFree,
    'plan-summary-mobile-pro': !isFree,
    'plan-summary-mobile-open-free': isFree && isOpen,
    'plan-summary-mobile-open-pro': !isFree && isOpen,
  });
  const contentClassNames = classNames('plan-summary-mobile__content', {
    'plan-summary-mobile__content-open': isOpen,
  });

  return (
    <>
      <div className={'plan-summary-mobile__header-holder'} />
      <div
        className={containerClassNames}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <div className="plan-summary-mobile__header">{titleText}</div>
        <div className={contentClassNames}>
          <PlanDetail planType={planType} />
          {!isFree && <PriceDetails tax={tax} />}
        </div>
        <button
          id="button-toggle-summary"
          style={{transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)'}}
          aria-label={isOpen ? 'Collapse' : 'Expand'}
        >
          <ArrowDownIcon />
        </button>
      </div>
    </>
  );
};

export default PlanSummaryMobile;
