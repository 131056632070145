export const getQueryString = (param) => {
  const params = new URLSearchParams(window.location.search);
  return params.get(param);
};

export const deconstructInvitationCode = (invitationCode) => {
  const [id, encodedEmail] = invitationCode.split(':');
  if (!id || !encodedEmail) {
    return {
      id: '',
      email: '',
    };
  }
  return {
    id,
    email: atob(encodedEmail),
  };
};

export const getSourceSite = () => {
  let sourceSite = getQueryString('ss');
  const redirect = getQueryString('redirect');
  if (!sourceSite && redirect) {
    sourceSite = new URL(redirect).searchParams.get('ss');
  }
  return sourceSite;
};
