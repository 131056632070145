import {useContext} from 'react';
import {globalUIContext} from '../../utils/global-ui-helpers';
import TeamMemberSignupForm from '../../components/team-member-signup-form';

const TeamMemberSignupPage = () => {
  const {invitationId, email, setBody} = useContext(globalUIContext);
  return (
    <TeamMemberSignupForm
      email={email}
      invitationId={invitationId}
      setBody={setBody}
    />
  );
};

export default TeamMemberSignupPage;
