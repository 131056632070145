import {useEffect} from 'react';
import CircularProgressBar from '../circular-progress-bar';
import {updatePageTitle} from '../../../utils/page-title-helpers';
import './index.less';

const FormHeader = ({
  current = 1,
  max = 2,
  headerText,
  enableProgressIcon = true,
}) => {
  useEffect(() => {
    updatePageTitle(headerText);
  }, []);
  return (
    <div className="form-header">
      {enableProgressIcon && (
        <CircularProgressBar current={current} max={max} />
      )}
      <h1>{headerText}</h1>
    </div>
  );
};

export default FormHeader;
