// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payment-processing-modal {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  min-width: 295px;
  font-family: Rubik;
  font-feature-settings: 'clig' off, 'liga' off;
  font-style: normal;
  text-align: center;
}
.payment-processing-modal__header {
  margin: 24px 24px 0 24px;
}
.payment-processing-modal__header h1 {
  color: #1a2026;
  font-size: 24px;
  font-weight: 400;
  line-height: 125%;
}
.payment-processing-modal__content {
  margin: 16px 24px 16px 24px;
  color: #69717a;
  font-size: 14px;
}
.payment-processing-modal__content p {
  line-height: 150%;
}
.payment-processing-modal__spinner {
  width: 100%;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.payment-processing-modal__spinner img {
  height: 100px;
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/payment-processing-modal/index.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,gBAAA;EACA,kBAAA;EACA,6CAAA;EAGA,kBAAA;EACA,kBAAA;AAHF;AAKE;EACE,wBAAA;AAHJ;AAEE;EAII,cAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AAHN;AAOE;EACE,2BAAA;EACA,cAAA;EACA,eAAA;AALJ;AAEE;EAMI,iBAAA;AALN;AASE;EACE,WAAA;EACA,oBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAPJ;AAEE;EAOI,aAAA;AANN","sourcesContent":["@import '../../../style/colors.less';\n\n.payment-processing-modal {\n  display: flex;\n  flex-direction: column;\n  max-width: 400px;\n  min-width: 295px;\n  font-family: Rubik;\n  font-feature-settings:\n    'clig' off,\n    'liga' off;\n  font-style: normal;\n  text-align: center;\n\n  &__header {\n    margin: 24px 24px 0 24px;\n\n    h1 {\n      color: @TextIconInteractive-base;\n      font-size: 24px;\n      font-weight: 400;\n      line-height: 125%;\n    }\n  }\n\n  &__content {\n    margin: 16px 24px 16px 24px;\n    color: @TextIconInteractive-soft;\n    font-size: 14px;\n\n    p {\n      line-height: 150%;\n    }\n  }\n\n  &__spinner {\n    width: 100%;\n    padding-bottom: 20px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    img {\n      height: 100px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
