// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-header {
  width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #d9dcde;
}
.form-header h1 {
  font-size: 24px;
  width: 100%;
  font-weight: 400;
  text-align: left;
  margin-left: 8px;
  line-height: 36px;
}
@media (max-width: 1080px) {
  .form-header {
    width: 304px;
    height: 70px;
  }
  .form-header .circular-progress-bar__label {
    left: 16px;
  }
  .form-header h2 {
    font-size: 18px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/shared/form-header/index.less"],"names":[],"mappings":"AAGA;EACE,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,gCAAA;AAFF;AAHA;EAOI,eAAA;EACA,WAAA;EACA,gBAAA;EACA,gBAAA;EACA,gBAAA;EACA,iBAAA;AADJ;AAKA;EACE;IACE,YAAA;IACA,YAAA;EAHF;EACA;IAII,UAAA;EAFJ;EAFA;IAOI,eAAA;EAFJ;AACF","sourcesContent":["@import '../../../style/colors.less';\n@import '../../../style/mobile.less';\n\n.form-header {\n  width: 600px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border-bottom: 1px solid @BgBorderNeutral15;\n  h1 {\n    font-size: 24px;\n    width: 100%;\n    font-weight: 400;\n    text-align: left;\n    margin-left: 8px;\n    line-height: 36px;\n  }\n}\n\n@media (max-width: @MobileMaxWidth) {\n  .form-header {\n    width: @MobileFormWidth;\n    height: 70px;\n    .circular-progress-bar__label {\n      left: 16px;\n    }\n    h2 {\n      font-size: 18px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
