import {useEffect} from 'react';
import LoginForm from '../../components/login-form';
import AdContainer from '../../components/ad-container';
import {SOCIAL_TABLES_TITLE} from '../../constants/page-title';
import {updatePageTitle} from '../../utils/page-title-helpers';

import './index.less';

const LoginPage = () => {
  useEffect(() => {
    updatePageTitle(SOCIAL_TABLES_TITLE);
  }, []);
  return (
    <div className="login-ctn">
      <LoginForm />
      <AdContainer />
    </div>
  );
};
export default LoginPage;
