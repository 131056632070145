// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.billing-success {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.billing-success__image {
  height: 400px;
  width: 400px;
}
.billing-success__heading {
  margin-top: 16px;
  font-size: 24px;
  line-height: 125%;
  font-weight: 300;
}
.billing-success__button {
  width: 132px;
  height: 40px;
  margin-top: 16px;
}
.billing-success__footer {
  width: 480px;
  color: #69717a;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  margin-top: 16px;
}
.billing-success__footer__email {
  color: #006ae1;
  display: inline;
}
@media (max-width: 1080px) {
  .billing-success {
    width: 304px;
  }
  .billing-success__image {
    width: 304px;
  }
  .billing-success__heading {
    margin-top: 0;
    font-size: 18px;
  }
  .billing-success__footer {
    width: 304px;
    text-wrap: wrap;
    text-align: center;
  }
}
`, "",{"version":3,"sources":["webpack://./src/routes/billing-page-success/index.less"],"names":[],"mappings":"AAGA;EACE,WAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;AAFF;AAGE;EACE,aAAA;EACA,YAAA;AADJ;AAGE;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;AADJ;AAGE;EACE,YAAA;EACA,YAAA;EACA,gBAAA;AADJ;AAGE;EACE,YAAA;EACA,cAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,gBAAA;AADJ;AAEI;EACE,cAAA;EACA,eAAA;AAAN;AAKA;EACE;IACE,YAAA;EAHF;EAIE;IACE,YAAA;EAFJ;EAIE;IACE,aAAA;IACA,eAAA;EAFJ;EAIE;IACE,YAAA;IACA,eAAA;IACA,kBAAA;EAFJ;AACF","sourcesContent":["@import '../../style/colors.less';\n@import '../../style/mobile.less';\n\n.billing-success {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  &__image {\n    height: 400px;\n    width: 400px;\n  }\n  &__heading {\n    margin-top: 16px;\n    font-size: 24px;\n    line-height: 125%;\n    font-weight: 300;\n  }\n  &__button {\n    width: 132px;\n    height: 40px;\n    margin-top: 16px;\n  }\n  &__footer {\n    width: 480px;\n    color: @TextIconInteractive-soft;\n    font-size: 14px;\n    line-height: 150%;\n    text-align: center;\n    margin-top: 16px;\n    &__email {\n      color: @TextIconInteractive-interactive;\n      display: inline;\n    }\n  }\n}\n\n@media (max-width: @MobileMaxWidth) {\n  .billing-success {\n    width: @MobileFormWidth;\n    &__image {\n      width: @MobileFormWidth;\n    }\n    &__heading {\n      margin-top: 0;\n      font-size: 18px;\n    }\n    &__footer {\n      width: @MobileFormWidth;\n      text-wrap: wrap;\n      text-align: center;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
