import {useCallback} from 'react';
import {FREE_PLAN_NAME, PRO_PLAN_NAME} from '../../constants/plan';
import classNames from 'classnames';
import Button from '../shared/button';
import {ExternalLink, REQUEST_PRICING} from '../shared/external-link';
import {CONFIRM_PLAN_TITLE} from '../../constants/page-title';
import './index.less';

const PlanPicker = ({
  selectedPlanType = FREE_PLAN_NAME,
  setFreePlanType,
  setProPlanType,
  handleNextStep,
}) => {
  const handleOnChange = useCallback((e) => {
    if (e.target.value == FREE_PLAN_NAME) {
      setFreePlanType();
    }
    if (e.target.value == PRO_PLAN_NAME) {
      setProPlanType();
    }
  }, []);
  const isProPlanSelected = selectedPlanType === PRO_PLAN_NAME;
  const getOptionClassNames = useCallback(
    (plan) => {
      return classNames('plan-selection-container__selection__option', {
        'plan-selection-container__selection__option--selected':
          plan === selectedPlanType,
      });
    },
    [selectedPlanType]
  );

  return (
    <div className="plan-selection-container">
      <div className="plan-selection-container__header">
        <h1>{CONFIRM_PLAN_TITLE}</h1>
      </div>
      <fieldset
        id="plan-option-free"
        className="plan-selection-container__selection"
      >
        <label className={getOptionClassNames(FREE_PLAN_NAME)}>
          <span className="plan-selection-container__selection__option__name">
            Free Trial
          </span>
          <hr className="plan-selection-container__selection__option__divider" />
          <span className="plan-selection-container__selection__option__price">
            $0
            <span className="plan-selection-container__selection__option__price__text">
              /Month
            </span>
          </span>
          <input
            type="radio"
            name="plan-type"
            aria-label="Free Trial $0 per Month"
            value={FREE_PLAN_NAME}
            defaultChecked={!isProPlanSelected}
            onChange={handleOnChange}
          />
        </label>
        <label
          id="plan-option-pro"
          className={getOptionClassNames(PRO_PLAN_NAME)}
        >
          <span className="plan-selection-container__selection__option__name">
            PRO Plan
          </span>
          <hr className="plan-selection-container__selection__option__divider" />
          <span className="plan-selection-container__selection__option__price">
            $199
            <span className="plan-selection-container__selection__option__price__text">
              /Month
            </span>
          </span>
          <input
            type="radio"
            name="plan-type"
            aria-label="PRO Plan $199 per Month"
            value={PRO_PLAN_NAME}
            defaultChecked={isProPlanSelected}
            onChange={handleOnChange}
          />
        </label>
      </fieldset>
      <div className="plan-selection-container__text">
        Unlock unlimited users and events with our Premium Plan.{' '}
        <ExternalLink type={REQUEST_PRICING} />
      </div>
      <div className="plan-selection-container__button">
        <Button
          inputFieldKey="next"
          text="Next"
          ariaLabel={
            isProPlanSelected ? 'confirm pro plan' : 'confirm free plan'
          }
          onClick={handleNextStep}
        />
      </div>
    </div>
  );
};

export default PlanPicker;
