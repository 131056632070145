import {useEffect, useState, useContext} from 'react';
import {useParams, useNavigate, useSearchParams} from 'react-router-dom';
import {globalUIContext} from '../../utils/global-ui-helpers';
import {FloorIcon} from '../../components/shared/icons';
import {deconstructInvitationCode} from '../../utils/url-helpers';
import PlanPicker from '../../components/plan-picker';
import PlanSummary from '../../components/plan-summary';
import PlanSummaryMobile from '../../components/plan-summary-mobile';
import './index.less';

const JoinEventPage = () => {
  const {
    isMobile,
    selectedPlanType,
    setIsCollaborator,
    setGlobalEmail,
    setFreePlanType,
    setProPlanType,
  } = useContext(globalUIContext);
  const navigate = useNavigate();
  const {invitationCode} = useParams();
  const {id: inviteId} = deconstructInvitationCode(invitationCode);
  const [queryParameters] = useSearchParams();
  const eventName = queryParameters?.get('event_name') || 'Collaboration Event';
  const [loading, setLoading] = useState(true);
  const [invitationData, setInvitationData] = useState({});

  useEffect(() => {
    const fetchInvitationData = async () => {
      try {
        const response = await fetch(`/invitation/event/${inviteId}`, {
          method: 'GET',
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json',
            'X-Forwarded-Proto': 'https',
          },
        }).then((res) => res.json());
        setInvitationData(response);
        setIsCollaborator(true);
        setGlobalEmail(response?.invitation?.email);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    fetchInvitationData();
  }, []);

  if (loading) {
    return;
  }

  return (
    <div className="join-event-page">
      <p>
        <span className="bold">{invitationData?.inviter?.name}</span> (
        {invitationData?.inviter?.email}) shared the event with you:
      </p>
      <div className="join-event-page__event-name">
        <FloorIcon />
        <span className="bold">{eventName}</span>
      </div>
      <PlanPicker
        selectedPlanType={selectedPlanType}
        setFreePlanType={setFreePlanType}
        setProPlanType={setProPlanType}
        handleNextStep={() => navigate('/signup/form')}
      />
      {isMobile ? (
        <PlanSummaryMobile planType={selectedPlanType} />
      ) : (
        <PlanSummary planType={selectedPlanType} />
      )}
    </div>
  );
};

export default JoinEventPage;
