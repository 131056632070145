const EyesIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.8984 11.6C23.6984 11.2 19.4984 3 11.9984 3C4.49844 3 0.298438 11.2 0.0984375 11.6C-0.0015625 11.9 -0.0015625 12.2 0.0984375 12.5C0.298438 12.8 4.49844 21 11.9984 21C19.4984 21 23.6984 12.8 23.8984 12.4C23.9984 12.2 23.9984 11.8 23.8984 11.6ZM11.9984 19C6.59844 19 3.09844 13.6 2.09844 12C2.99844 10.4 6.59844 5 11.9984 5C17.3984 5 20.8984 10.4 21.8984 12C20.8984 13.6 17.3984 19 11.9984 19ZM11.9984 8C9.79844 8 7.99844 9.8 7.99844 12C7.99844 14.2 9.79844 16 11.9984 16C14.1984 16 15.9984 14.2 15.9984 12C15.9984 9.8 14.1984 8 11.9984 8ZM11.9984 14C10.8984 14 9.99844 13.1 9.99844 12C9.99844 10.9 10.8984 10 11.9984 10C13.0984 10 13.9984 10.9 13.9984 12C13.9984 13.1 13.0984 14 11.9984 14Z"
      fill="#69717A"
    />
  </svg>
);

export default EyesIcon;
