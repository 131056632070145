import {useCallback, useContext, useState} from 'react';
import classNames from 'classnames';
import {useSearchParams, useNavigate} from 'react-router-dom';
import {FREE_PLAN_NAME, PRO_PLAN_NAME} from '../../constants/plan';
import {globalUIContext} from '../../utils/global-ui-helpers';
import CreatePasswordForm from '../../components/create-password-form';
import FormHeader from '../../components/shared/form-header';
import PlanSummary from '../../components/plan-summary';
import PlanSummaryMobile from '../../components/plan-summary-mobile';
import './index.less';

const getPasswordPageConfig = (plan) => {
  switch (plan) {
    case FREE_PLAN_NAME:
      return {
        displayHeader: true,
        isFirstPassword: true,
        current: 2,
        max: 2,
        title: 'Create Password',
        isNewPassword: true,
      };
    case PRO_PLAN_NAME:
      return {
        displayHeader: true,
        isFirstPassword: true,
        current: 2,
        max: 3,
        title: 'Create Password',
      };
    default:
      return {
        displayHeader: false,
        isFirstPassword: false,
        title: 'Create a new password',
      };
  }
};

const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const {
    isMobile,
    email,
    isCollaborator,
    setGlobalEmail,
    selectedPlanType,
    body,
  } = useContext(globalUIContext);
  const [resError, setResError] = useState(null);
  const [queryParameters] = useSearchParams();
  const resetToken = queryParameters.get('resetToken');
  if (!resetToken && !isCollaborator) {
    return;
  }
  const planType = resetToken ? queryParameters.get('plan') : selectedPlanType;
  const {displayHeader, isFirstPassword, current, max, title} =
    getPasswordPageConfig(planType);
  const displayPlanSummary = !isMobile && planType;
  const displayMobilePlanSummary = isMobile && planType;

  const handleResetPassword = useCallback(
    async ({password, confirmPassword}) => {
      setResError(null);
      const body = {
        password,
        confirm_password: confirmPassword,
        _csrf: window.csrfToken || '',
      };

      try {
        const response = await fetch(
          `/reset-password?resetToken=${resetToken}`,
          {
            method: 'POST',
            credentials: 'same-origin',
            headers: {
              'Content-Type': 'application/json',
              'X-Forwarded-Proto': 'https',
            },
            body: JSON.stringify(body),
          }
        ).then((res) => res.json());
        if (response.message || !response.user) {
          return setResError(response.message);
        }
        if (planType == FREE_PLAN_NAME) {
          return navigate('/success');
        }
        if (planType == PRO_PLAN_NAME) {
          setGlobalEmail(response.user.email);
          return navigate('/billing');
        }
        return navigate('/reset-password-success');
      } catch (error) {
        return navigate('/failure-general');
      }
    }
  );

  const handleCreateCollaborator = useCallback(
    async ({password, confirmPassword}) => {
      setResError(null);
      const request = {
        ...body,
        email,
        password,
        confirm_password: confirmPassword,
        _csrf: window.csrfToken || '',
      };
      try {
        const response = await fetch('/register-collaborator', {
          method: 'POST',
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json',
            'X-Forwarded-Proto': 'https',
          },
          body: JSON.stringify(request),
        }).then((res) => res.json());
        if (response.message || !response.user) {
          return setResError(response.message);
        }
        if (planType == PRO_PLAN_NAME) {
          setGlobalEmail(response.user.email);
          return navigate('/billing');
        }
        return navigate('/success');
      } catch (error) {
        return navigate('/failure-general');
      }
    }
  );

  const fieldClassNames = classNames('reset-password-page', {
    'reset-password-page-base': !planType,
  });

  return (
    <>
      <div className={fieldClassNames}>
        {displayHeader && (
          <FormHeader current={current} max={max} headerText={title} />
        )}
        {!displayHeader && <h3>{title}</h3>}
        <CreatePasswordForm
          handleResetPassword={
            isCollaborator ? handleCreateCollaborator : handleResetPassword
          }
          isFirstPassword={isFirstPassword}
          resError={resError}
          setResError={setResError}
        />
      </div>
      {displayPlanSummary && <PlanSummary planType={planType} />}
      {displayMobilePlanSummary && <PlanSummaryMobile planType={planType} />}
    </>
  );
};

export default ResetPasswordPage;
