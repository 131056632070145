// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.background {
  position: fixed;
  top: 0;
  right: 0;
  width: 60%;
  height: 100vh;
  z-index: -99;
}
.background img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
`, "",{"version":3,"sources":["webpack://./src/components/background/index.less"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,MAAA;EACA,QAAA;EACA,UAAA;EACA,aAAA;EACA,YAAA;AACF;AAPA;EAQI,YAAA;EACA,WAAA;EACA,iBAAA;AAEJ","sourcesContent":[".background {\n  position: fixed;\n  top: 0;\n  right: 0;\n  width: 60%;\n  height: 100vh;\n  z-index: -99;\n  img {\n    height: 100%;\n    width: 100%;\n    object-fit: cover;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
