import {useContext, useEffect, useRef} from 'react';
import {createPortal} from 'react-dom';
import {globalUIContext} from '../../utils/global-ui-helpers';
import {
  EMAIL_SENT_MODAL,
  HELP_AND_SUPPORT_MODAL,
  PAYMENT_ERROR_MODAL,
  PAYMENT_PROCESSING_MODAL,
  SUSPENDED_MODAL,
  INCOMPLETE_BILLING_MODAL,
  REACTIVATE_ACCOUNT_MODAL,
} from '../../constants/modals';
import EmailSentModal from './email-sent-modal';
import HelpAndSupportModal from './help-and-support-modal';
import PaymentErrorModal from './payment-error-modal';
import PaymentProcessingModal from './payment-processing-modal';
import SuspendedModal from './suspended-modal';
import IncompleteBillingModal from './incomplete-billing-modal';
import ReactivateAccountModal from './reactivate-account-modal';
import './index.less';
import FocusTrap from 'focus-trap-react';

const getModal = (modal, closeModal, isMobile, initialFocus) => {
  switch (modal?.modalName) {
    case EMAIL_SENT_MODAL:
      return (
        <EmailSentModal
          {...modal?.modalProps}
          handleClose={closeModal}
          initialFocus={initialFocus}
        />
      );
    case HELP_AND_SUPPORT_MODAL:
      return (
        <HelpAndSupportModal
          {...modal?.modalProps}
          handleClose={closeModal}
          isMobile={isMobile}
          initialFocus={initialFocus}
        />
      );
    case PAYMENT_ERROR_MODAL:
      return (
        <PaymentErrorModal
          {...modal?.modalProps}
          handleClose={closeModal}
          initialFocus={initialFocus}
        />
      );
    case PAYMENT_PROCESSING_MODAL:
      return (
        <PaymentProcessingModal
          {...modal?.modalProps}
          initialFocus={initialFocus}
        />
      );
    case SUSPENDED_MODAL:
      return (
        <SuspendedModal
          {...modal?.modalProps}
          handleClose={closeModal}
          initialFocus={initialFocus}
        />
      );
    case INCOMPLETE_BILLING_MODAL:
      return (
        <IncompleteBillingModal
          {...modal?.modalProps}
          initialFocus={initialFocus}
        />
      );
    case REACTIVATE_ACCOUNT_MODAL:
      return (
        <ReactivateAccountModal
          {...modal?.modalProps}
          handleClose={closeModal}
          initialFocus={initialFocus}
        />
      );
    default:
      return null;
  }
};

const Modal = () => {
  const {modal, closeModal, isMobile} = useContext(globalUIContext);
  const initialFocus = useRef();
  useEffect(() => {
    if (modal?.modalProps?.canClose) {
      const closeOnEscapeKey = (e) =>
        e.key === 'Escape' ? closeModal() : null;
      document.body.addEventListener('keydown', closeOnEscapeKey);
      return () => {
        document.body.removeEventListener('keydown', closeOnEscapeKey);
      };
    }
  }, [modal, closeModal]);

  const selectedModal = getModal(modal, closeModal, isMobile, initialFocus);
  return (
    selectedModal &&
    createPortal(
      <div className="earth-portal-modal">
        <div
          className="earth-portal-modal__backdrop"
          onClick={() => modal?.modalProps?.canClose && closeModal()}
        ></div>
        <FocusTrap
          focusTrapOptions={{initialFocus: () => initialFocus.current}}
        >
          <div className="earth-portal-modal__container">{selectedModal}</div>
        </FocusTrap>
      </div>,
      document.body
    )
  );
};

export default Modal;
