import {loadStripe} from '@stripe/stripe-js/pure';
import {Elements} from '@stripe/react-stripe-js';
import BillingForm from './form';

let stripePromise;

const BillingFormWrapper = ({email, enableProgressIcon = true}) => {
  if (!stripePromise) {
    stripePromise = loadStripe(window?.stripeInit?.publishableKey);
  }
  if (!email) {
    return;
  }
  return (
    <Elements stripe={stripePromise}>
      <BillingForm email={email} enableProgressIcon={enableProgressIcon} />
    </Elements>
  );
};

export default BillingFormWrapper;
