const CheckCircleIcon = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="&#194;&#169; v1 check-circle">
      <path
        id="Flattened"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.0013 1.25C3.93464 1.25 0.667969 4.51667 0.667969 8.58333C0.667969 12.65 3.93464 15.9167 8.0013 15.9167C12.068 15.9167 15.3346 12.65 15.3346 8.58333C15.3346 4.51667 12.068 1.25 8.0013 1.25ZM8.0013 14.5833C4.66797 14.5833 2.0013 11.9167 2.0013 8.58333C2.0013 5.25 4.66797 2.58333 8.0013 2.58333C11.3346 2.58333 14.0013 5.25 14.0013 8.58333C14.0013 11.9167 11.3346 14.5833 8.0013 14.5833ZM7.33463 9.64052L10.8632 6.11193C11.1236 5.85158 11.5457 5.85158 11.806 6.11193C12.0664 6.37228 12.0664 6.79439 11.806 7.05474L7.80604 11.0547C7.54569 11.3151 7.12358 11.3151 6.86323 11.0547L4.86323 9.05474C4.60288 8.79439 4.60288 8.37228 4.86323 8.11193C5.12358 7.85158 5.54569 7.85158 5.80604 8.11193L7.33463 9.64052Z"
        fill="#008600"
      />
    </g>
  </svg>
);

export default CheckCircleIcon;
