import React, {useState, useEffect, useRef} from 'react';
import classNames from 'classnames';
import {ArrowDownIcon, AlertTriangle} from '../icons/index';
import './index.less';

const Dropdown = ({
  inputFieldKey,
  options = [],
  selectedOption = '',
  onSelectionChange,
  placeholder = 'Select an option',
  labelText = '',
  error = '',
  isRequired = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const dropdownRef = useRef(null);
  const optionRefs = useRef([]);
  const [searchTerm, setSearchTerm] = useState('');

  const selectedOptionObject = options.find(
    (option) => option.value == selectedOption
  );

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleSearch = (event) => {
    if (/^[a-zA-Z0-9]$/.test(event.key)) {
      const newSearchTerm = searchTerm + event.key;

      let newIndex = -1;
      for (let i = 0; i < options.length; i++) {
        if (
          options[i].label.toLowerCase().startsWith(newSearchTerm.toLowerCase())
        ) {
          newIndex = i;
          break;
        }
      }

      if (newIndex !== -1) {
        setHighlightedIndex(newIndex);
      }
      setSearchTerm(newSearchTerm);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    if (
      isOpen &&
      highlightedIndex >= 0 &&
      optionRefs.current[highlightedIndex]
    ) {
      optionRefs.current[highlightedIndex].focus();
    }
  }, [isOpen, highlightedIndex]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSearchTerm('');
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [searchTerm]);

  const handleSelectionChange = (option) => {
    onSelectionChange(option.value);
    setIsOpen(false);
  };

  const handleKeyDown = (event) => {
    event.stopPropagation();
    switch (event.key) {
      case 'Enter':
        event.preventDefault();
        setIsOpen(!isOpen);
        setSearchTerm('');
        break;
      case 'Escape':
        event.preventDefault();
        setIsOpen(false);
        setSearchTerm('');
        break;
      case 'ArrowUp':
        event.preventDefault();
        setHighlightedIndex((prevIndex) =>
          prevIndex <= 0 ? options.length - 1 : prevIndex - 1
        );
        setSearchTerm('');
        break;
      case 'ArrowDown':
        event.preventDefault();
        setHighlightedIndex((prevIndex) =>
          prevIndex >= options.length - 1 ? 0 : prevIndex + 1
        );
        setSearchTerm('');
        break;
      default:
        handleSearch(event);
        break;
    }
  };

  const handleOptionKeyDown = (event, option) => {
    event.preventDefault();
    event.stopPropagation();
    switch (event.key) {
      case 'Enter':
        handleSelectionChange(option);
        setSearchTerm('');
        break;
      case 'Escape':
        setIsOpen(false);
        setSearchTerm('');
        break;
      case 'ArrowUp':
        setHighlightedIndex((prevIndex) =>
          prevIndex <= 0 ? options.length - 1 : prevIndex - 1
        );
        setSearchTerm('');
        break;
      case 'ArrowDown':
        setHighlightedIndex((prevIndex) =>
          prevIndex >= options.length - 1 ? 0 : prevIndex + 1
        );
        setSearchTerm('');
        break;
      default:
        handleSearch(event);
        break;
    }
  };

  const dropdownButtonClassnames = classNames('dropdown-button', {
    'dropdown-button-selected': selectedOption,
    'dropdown-button-error': error,
  });

  return (
    <div className="dropdown-container" ref={dropdownRef}>
      <label>
        {isRequired && (
          <span className="dropdown-container__is-required">*</span>
        )}
        {labelText}
      </label>
      <button
        id={`field-${inputFieldKey}`}
        type="button"
        className={dropdownButtonClassnames}
        onClick={() => setIsOpen(!isOpen)}
        onKeyDown={handleKeyDown}
        aria-haspopup="listbox"
        aria-expanded={isOpen}
        aria-labelledby={`field-${inputFieldKey}`}
      >
        {selectedOptionObject ? selectedOptionObject.label : placeholder}
        <div className={isOpen ? 'dropdown-button__icon-rotate' : ''}>
          <ArrowDownIcon />
        </div>
      </button>
      {error && (
        <div className="dropdown-container__error">
          <AlertTriangle />{' '}
          <span id={`field-error-${inputFieldKey}`}>{error}</span>
        </div>
      )}

      {isOpen && (
        <ul
          className="dropdown-options"
          role="listbox"
          aria-labelledby={`field-${inputFieldKey}`}
        >
          {options.map((option, index) => {
            const optionClassnames = classNames('dropdown-option', {
              'dropdown-option-selected': option.value === selectedOption,
              'dropdown-option-highlighted': index === highlightedIndex,
            });
            return (
              <li
                id={`dropdown-option-${option.value}`}
                key={option.value}
                className={optionClassnames}
                onClick={() => handleSelectionChange(option)}
                onKeyDown={(event) => handleOptionKeyDown(event, option)}
                role="option"
                aria-selected={option.value === selectedOption}
                aria-labelledby={`option-${option.label}-${option.description}`}
                tabIndex={0}
                ref={(ref) => (optionRefs.current[index] = ref)}
              >
                {option.label}
                {option.description && <div> {option.description} </div>}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
