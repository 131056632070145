import React from 'react';
import connectingImage from '../../assets/connecting.svg';
import Footer from '../../components/footer';
import Button from '../../components/shared/button';

import './index.less';

const OauthAuthorizeForm = () => {
  const appName = window.app_name || 'this app';
  const oauthParams = window.oauthParams || {};

  return (
    <div className="oauth-authorize-form">
      <img
        src={connectingImage}
        className="oauth-authorize-form__image"
        alt="error image"
        aria-hidden="true"
      />
      <h1 className="oauth-authorize-form__heading">Get Connected</h1>
      <span className="oauth-authorize-form__message">
        {`Do you want to grant ${appName} access to your diagramming data?`}
      </span>
      <form action={window.action} method="post">
        <input type="hidden" name="client_id" value={oauthParams.client_id} />
        <input
          type="hidden"
          name="redirect_uri"
          value={oauthParams.redirect_uri}
        />
        <input
          type="hidden"
          name="response_type"
          value={oauthParams.response_type}
        />
        <input type="hidden" name="scope" value={oauthParams.scope} />
        <input type="hidden" name="state" value={oauthParams.state} />
        <input type="hidden" name="_csrf" value={window.csrfToken} />
        <div className="oauth-authorize-form__btn-container">
          <Button
            className="oauth-authorize-form__button"
            isPrimary={false}
            text="No"
            name="no"
            value="no"
            type="submit"
          />
          <Button
            className="oauth-authorize-form__button"
            isPrimary={true}
            text="Yes"
            name="allow"
            value="allow"
            type="submit"
          />
        </div>
      </form>
      <Footer />
    </div>
  );
};

export default OauthAuthorizeForm;
