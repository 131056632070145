import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import superagent from 'superagent';
import './index.less';

/*
    Component for initial saml redirect when user visits GET earth-portal/saml/{IDP_NAME}
    This component loads and immediately loads the actionUrl passed in via props
    Should follow logic from authenticator
    https://github.com/socialtables/authenticator/blob/main/templates/saml-redirect-form.jsx
*/

const SamlRedirectForm = () => {
  const [ready, setReady] = useState(false);
  const [saml, setSaml] = useState('');
  const [actionUrl, setActionUrl] = useState('');
  const [samlType, setSamlType] = useState('');
  const [relayState, setRelayState] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const idpName = window.location.pathname.split('/')[2];
    superagent
      .get(`/idp/${idpName}`)
      .withCredentials()
      .set({
        'Content-Type': 'application/json',
        'X-Forwarded-Proto': 'https',
      })
      .then(({body}) => {
        if (body) {
          setReady(true);
          setSaml(body.saml);
          setRelayState(body.relayState);
          setActionUrl(body.actionUrl);
          setSamlType(body.samlType);
        }
      })
      .catch(() => {
        navigate('/saml-error');
      });
  }, []);

  useEffect(() => {
    if (ready) {
      document.getElementById('appForm').submit();
    }
  }, [ready]);

  return (
    <div className="saml-redirect">
      <section>
        <h1 className="saml-redirect__header">Signing in to Social Tables</h1>
        <div className="saml-redirect__spinner">
          <img
            src="https://assets.socialtables.com/cvent-spinner.gif"
            alt="Payment is processing"
            aria-hidden="true"
          />
        </div>
        <form id="appForm" action={actionUrl} method="post">
          <input type="hidden" name={samlType} value={saml} />
          <input type="hidden" name="RelayState" value={relayState} />
        </form>
      </section>
    </div>
  );
};

export default SamlRedirectForm;
