const NewTab = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0026 7.0026C10.6026 7.0026 10.3359 7.26927 10.3359 7.66927V11.6693C10.3359 12.0693 10.0693 12.3359 9.66927 12.3359H2.33594C1.93594 12.3359 1.66927 12.0693 1.66927 11.6693V4.33594C1.66927 3.93594 1.93594 3.66927 2.33594 3.66927H6.33594C6.73594 3.66927 7.0026 3.4026 7.0026 3.0026C7.0026 2.6026 6.73594 2.33594 6.33594 2.33594H2.33594C1.2026 2.33594 0.335938 3.2026 0.335938 4.33594V11.6693C0.335938 12.8026 1.2026 13.6693 2.33594 13.6693H9.66927C10.8026 13.6693 11.6693 12.8026 11.6693 11.6693V7.66927C11.6693 7.26927 11.4026 7.0026 11.0026 7.0026ZM13.6026 0.735938C13.5359 0.602604 13.4026 0.469271 13.2693 0.402604C13.2026 0.335938 13.0693 0.335938 13.0026 0.335938H9.0026C8.6026 0.335938 8.33594 0.602604 8.33594 1.0026C8.33594 1.4026 8.6026 1.66927 9.0026 1.66927H11.4026L5.2026 7.86927C4.93594 8.13594 4.93594 8.53594 5.2026 8.8026C5.33594 8.93594 5.46927 9.0026 5.66927 9.0026C5.86927 9.0026 6.0026 8.93594 6.13594 8.8026L12.3359 2.6026V5.0026C12.3359 5.4026 12.6026 5.66927 13.0026 5.66927C13.4026 5.66927 13.6693 5.4026 13.6693 5.0026V1.0026C13.6693 0.935938 13.6693 0.802604 13.6026 0.735938Z"
      fill="#006AE1"
    />
  </svg>
);

export default NewTab;
