// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.right-marketing-container img {
  width: 480px;
  height: 400px;
  border-radius: 20px;
  box-shadow: 0px 4px 20px -2px rgba(255, 255, 255, 0.25);
  background-color: white;
}
`, "",{"version":3,"sources":["webpack://./src/components/ad-container/index.less"],"names":[],"mappings":"AAAA;EAEI,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uDAAA;EACA,uBAAA;AAAJ","sourcesContent":[".right-marketing-container {\n  img {\n    width: 480px;\n    height: 400px;\n    border-radius: 20px;\n    box-shadow: 0px 4px 20px -2px rgba(255, 255, 255, 0.25);\n    background-color: white;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
