import {useState, useCallback, useContext, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import InputField from '../../components/shared/input-field';
import {globalUIContext} from '../../utils/global-ui-helpers';
import ChevronLeftIcon from '../../components/shared/icons/chevron-left';
import Button from '../../components/shared/button';
import {
  INVALID_EMAIL_ERROR_TEXT,
  REQUIRED_FORM_FIELD_TEXT,
} from '../../constants/errors';
import {FORGOT_PASSWORD_TITLE} from '../../constants/page-title';
import {updatePageTitle} from '../../utils/page-title-helpers';
import {EMAIL_FIELD} from '../../constants/form-fields';
import {EMAIL_SENT_MODAL} from '../../constants/modals';
import {focusField} from '../../utils/form-helpers';
import {validateEmail} from '../../utils/validate-helpers';
import './index.less';

const FORGOT_PASSWORD_FORM_ORDER = [EMAIL_FIELD];

const ForgotPasswordPage = () => {
  const {openModal} = useContext(globalUIContext);
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [countdown, setCountdown] = useState(null);

  useEffect(() => {
    updatePageTitle(FORGOT_PASSWORD_TITLE);
  }, []);

  useEffect(() => {
    if (error) {
      focusField({[EMAIL_FIELD]: error}, FORGOT_PASSWORD_FORM_ORDER);
    }
  }, [error]);

  const validEmail = () => {
    let isValid = true;
    if (!email) {
      setError(REQUIRED_FORM_FIELD_TEXT);
      isValid = false;
    } else if (!validateEmail(email)) {
      setError(INVALID_EMAIL_ERROR_TEXT);
      isValid = false;
    }
    return isValid;
  };

  const submitForm = useCallback(async (e) => {
    e.preventDefault();
    setError(null);
    if (!validEmail()) {
      return;
    }

    const body = {
      email,
      _csrf: window.csrfToken || '',
    };

    try {
      const response = await fetch('/forgot-password', {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          'X-Forwarded-Proto': 'https',
        },
        body: JSON.stringify(body),
      });
      openModal(EMAIL_SENT_MODAL);
      startCountdown();
    } catch (error) {
      navigate('/failure-general');
    }
  });

  const startCountdown = () => {
    let remainingTime = 120; // 2 minutes in seconds
    setCountdown(formatTime(remainingTime));

    const interval = setInterval(() => {
      remainingTime--;
      setCountdown(formatTime(remainingTime));

      if (remainingTime <= 0) {
        clearInterval(interval);
        setCountdown(null);
      }
    }, 1000);
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes} min ${remainingSeconds} sec remaining to re-send email`;
  };

  return (
    <form className="forgot-password-form" onSubmit={submitForm}>
      <span
        id="button-back"
        className="breadcrumb"
        onClick={() => navigate(-1)}
        tabIndex="0"
        role="button"
      >
        <ChevronLeftIcon className="breadcrumb-icon" />
        Back
      </span>
      <h1>{FORGOT_PASSWORD_TITLE}</h1>
      <span className="subheading">
        Enter your email address for a link to reset your password
      </span>
      <InputField
        inputFieldKey={EMAIL_FIELD}
        labelText="Email"
        inputPlaceholder="name@companyname.com"
        value={email}
        onChange={setEmail}
        error={error}
      />
      <Button
        inputFieldKey="submit"
        text="Send email"
        type="submit"
        onClick={submitForm}
        ariaLabel="Send email"
        isDisabled={countdown}
      />
      {countdown && (
        <p className="forgot-password-form__countdown">{countdown}</p>
      )}
    </form>
  );
};

export default ForgotPasswordPage;
