// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create-password-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.create-password-form__status {
  width: 300px;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
}
.create-password-form__status__item:not(:first-child) {
  margin-top: 4px;
}
.create-password-form__status__item {
  color: #1a2026;
  font-size: 13px;
  font-weight: 300;
  line-height: 150%;
}
.create-password-form__status__item__sub-text {
  color: #69717a;
}
.create-password-form__status__item svg {
  margin-right: 8px;
  vertical-align: text-bottom;
}
.create-password-form__status__item svg path {
  fill: #9a9fa6;
}
.create-password-form__status__item__error svg path {
  fill: #db2c00;
}
.create-password-form__status__item__success {
  font-weight: 500;
}
.create-password-form__status__item__success svg path {
  fill: #008600;
}
.create-password-form__form .password-input-field {
  margin-top: 16px;
}
.create-password-form__form .password-input-field label {
  font-size: 13px;
  line-height: 150%;
  margin-bottom: 0;
}
.create-password-form__form .password-input-field .password-input-field__eye-icon {
  top: 28px;
}
.create-password-form__form__button {
  margin-top: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/components/create-password-form/index.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AADF;AAGE;EACE,YAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;AADJ;AAGI;EACE,eAAA;AADN;AAII;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AAFN;AAIM;EACE,cAAA;AAFR;AALI;EAWI,iBAAA;EACA,2BAAA;AAHR;AATI;EAgBI,aAAA;AAJR;AAOM;EAEI,aAAA;AANV;AAUM;EACE,gBAAA;AARR;AAOM;EAII,aAAA;AARV;AAcE;EAEI,gBAAA;AAbN;AAWE;EAKM,eAAA;EACA,iBAAA;EACA,gBAAA;AAbR;AAME;EAWM,SAAA;AAdR;AAkBI;EACE,gBAAA;AAhBN","sourcesContent":["@import '../../style/colors.less';\n\n.create-password-form {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n\n  &__status {\n    width: 300px;\n    margin-top: 24px;\n    display: flex;\n    flex-direction: column;\n\n    &__item:not(:first-child) {\n      margin-top: 4px;\n    }\n\n    &__item {\n      color: @TextIconInteractive-base;\n      font-size: 13px;\n      font-weight: 300;\n      line-height: 150%;\n\n      &__sub-text {\n        color: @TextIconInteractive-soft;\n      }\n\n      svg {\n        margin-right: 8px;\n        vertical-align: text-bottom;\n      }\n\n      svg path {\n        fill: @TextIconInteractive-disabled;\n      }\n\n      &__error {\n        svg path {\n          fill: @TextIconInteractive-danger;\n        }\n      }\n\n      &__success {\n        font-weight: 500;\n\n        svg path {\n          fill: @TextIconInteractive-success;\n        }\n      }\n    }\n  }\n\n  &__form {\n    .password-input-field {\n      margin-top: 16px;\n\n      label {\n        font-size: 13px;\n        line-height: 150%;\n        margin-bottom: 0;\n      }\n\n      .password-input-field__eye-icon {\n        top: 28px;\n      }\n    }\n\n    &__button {\n      margin-top: 16px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
