const LogOutIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.6668 1.33337H10.0001C9.6001 1.33337 9.33343 1.60004 9.33343 2.00004C9.33343 2.40004 9.6001 2.66671 10.0001 2.66671H12.6668C13.0668 2.66671 13.3334 2.93337 13.3334 3.33337V12.6667C13.3334 13.0667 13.0668 13.3334 12.6668 13.3334H10.0001C9.6001 13.3334 9.33343 13.6 9.33343 14C9.33343 14.4 9.6001 14.6667 10.0001 14.6667H12.6668C13.8001 14.6667 14.6668 13.8 14.6668 12.6667V3.33337C14.6668 2.20004 13.8001 1.33337 12.6668 1.33337ZM1.4001 7.73337C1.33343 7.86671 1.33343 8.06671 1.4001 8.26671C1.46676 8.33337 1.46676 8.40004 1.53343 8.46671L4.86676 11.8C5.13343 12.0667 5.53343 12.0667 5.8001 11.8C6.06676 11.5334 6.06676 11.1334 5.8001 10.8667L3.6001 8.66671H10.0001C10.4001 8.66671 10.6668 8.40004 10.6668 8.00004C10.6668 7.60004 10.4001 7.33337 10.0001 7.33337H3.6001L5.8001 5.13337C6.06676 4.86671 6.06676 4.46671 5.8001 4.20004C5.66676 4.06671 5.53343 4.00004 5.33343 4.00004C5.13343 4.00004 5.0001 4.06671 4.86676 4.20004L1.53343 7.53337C1.46676 7.60004 1.4001 7.66671 1.4001 7.73337Z"
      fill="#006AE1"
    />
  </svg>
);

export default LogOutIcon;
