import './index.less';

const PaymentProcessingModal = ({initialFocus}) => {
  return (
    <div className="payment-processing-modal">
      <div className="payment-processing-modal__header">
        <h1 ref={initialFocus} tabIndex="0">
          Payment is processing
        </h1>
      </div>
      <div className="payment-processing-modal__content">
        <p>Stay on this page to avoid disrupting the payment process.</p>
      </div>
      <div className="payment-processing-modal__spinner">
        <img
          src="https://assets.socialtables.com/cvent-spinner.gif"
          alt="Payment is processing"
          aria-hidden="true"
        />
      </div>
    </div>
  );
};

export default PaymentProcessingModal;
