const FloorIcon = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clip-rule="evenodd"
      d="M0 2C0 0.89543 0.895431 0 2 0H20C21.1046 0 22 0.895431 22 2V20C22 21.1046 21.1046 22 20 22H2C0.895431 22 0 21.1046 0 20V16C0 15.4477 0.447715 15 1 15C1.55228 15 2 15.4477 2 16V20H7V9C7 8.44772 7.44772 8 8 8C8.55228 8 9 8.44772 9 9V12H12C12.5523 12 13 12.4477 13 13C13 13.5523 12.5523 14 12 14H9V20H20V14H17C16.4477 14 16 13.5523 16 13C16 12.4477 16.4477 12 17 12H20V2H9V4C9 4.55228 8.55228 5 8 5C7.44772 5 7 4.55228 7 4V2H2V10C2 10.5523 1.55228 11 1 11C0.447715 11 0 10.5523 0 10V2Z"
      fill="#1A2026"
    />
  </svg>
);

export default FloorIcon;
