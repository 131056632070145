// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payment-error-modal {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  min-width: 295px;
  font-family: Rubik;
  font-feature-settings: 'clig' off, 'liga' off;
  font-style: normal;
}
.payment-error-modal__header {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 20px 24px 16px 24px;
}
.payment-error-modal__header__x {
  cursor: pointer;
}
.payment-error-modal__header__image {
  margin: 16px 0 16px 0;
  width: 100%;
  height: 120px;
}
.payment-error-modal__header h1 {
  color: #1a2026;
  font-size: 24px;
  font-weight: 400;
  line-height: 125%;
}
.payment-error-modal__content {
  margin: 0 24px 0 24px;
  color: #69717a;
  font-size: 14px;
}
.payment-error-modal__content p {
  line-height: 150%;
}
.payment-error-modal__content a {
  color: #006ae1;
  text-decoration-line: underline;
}
.payment-error-modal__actions {
  display: flex;
  justify-content: flex-end;
  margin: 16px 24px 16px 24px;
}
.payment-error-modal__actions__close-btn {
  width: 89px;
}
`, "",{"version":3,"sources":["webpack://./src/components/modals/payment-error-modal/index.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,gBAAA;EACA,kBAAA;EACA,6CAAA;EAGA,kBAAA;AAHF;AAKE;EACE,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,qBAAA;EACA,2BAAA;AAHJ;AAKI;EACE,eAAA;AAHN;AAMI;EACE,qBAAA;EACA,WAAA;EACA,aAAA;AAJN;AAVE;EAkBI,cAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AALN;AASE;EACE,qBAAA;EACA,cAAA;EACA,eAAA;AAPJ;AAIE;EAMI,iBAAA;AAPN;AACE;EAUI,cAAA;EACA,+BAAA;AARN;AAYE;EACE,aAAA;EACA,yBAAA;EACA,2BAAA;AAVJ;AAYI;EACE,WAAA;AAVN","sourcesContent":["@import '../../../style/colors.less';\n\n.payment-error-modal {\n  display: flex;\n  flex-direction: column;\n  max-width: 400px;\n  min-width: 295px;\n  font-family: Rubik;\n  font-feature-settings:\n    'clig' off,\n    'liga' off;\n  font-style: normal;\n\n  &__header {\n    text-align: center;\n    display: flex;\n    flex-direction: column;\n    align-items: flex-end;\n    margin: 20px 24px 16px 24px;\n\n    &__x {\n      cursor: pointer;\n    }\n\n    &__image {\n      margin: 16px 0 16px 0;\n      width: 100%;\n      height: 120px;\n    }\n\n    h1 {\n      color: @TextIconInteractive-base;\n      font-size: 24px;\n      font-weight: 400;\n      line-height: 125%;\n    }\n  }\n\n  &__content {\n    margin: 0 24px 0 24px;\n    color: @TextIconInteractive-soft;\n    font-size: 14px;\n\n    p {\n      line-height: 150%;\n    }\n\n    a {\n      color: @TextIconInteractive-interactive;\n      text-decoration-line: underline;\n    }\n  }\n\n  &__actions {\n    display: flex;\n    justify-content: flex-end;\n    margin: 16px 24px 16px 24px;\n\n    &__close-btn {\n      width: 89px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
